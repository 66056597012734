import { React, useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBytoken, userSelector } from "../store/userSlice";
import { Spinner } from 'reactstrap';
//import { CurrentUserContext } from "../context";


const PublicRoute = ({ component: Component, ...rest }) => {
  // const apiUrl = process.env.REACT_APP_API_KEY;
  const { loggedInStatus, privateLoadingStatus } = useSelector(userSelector);
  const dispatch = useDispatch();

  const [changeDone, setChangeDone] = useState(false);
  const location = useLocation();
  const currentLocation = location.pathname;


  useEffect(() => {
    dispatch(fetchUserBytoken({ token: localStorage.getItem('token') })).then(() =>
      setChangeDone(true)
    );
    // eslint-disable-next-line
  }, []);
  console.log('rest', rest)
  return (

    <Route {...rest} render={props => (
      <div>
        {
          (() => {
            if (rest?.path === '/:slug') {
              return (
                <Component {...props} />
              )
            }
            else if (privateLoadingStatus === "loaded" && changeDone) {
              if (!loggedInStatus) {
                return (
                  <Component {...props} />
                )
              } else {
                return <Redirect
                  to={{
                    pathname: '/dashboard',
                  }}
                />
              }
            } else if (privateLoadingStatus === "loading") {
              return <Spinner />
            } else if (privateLoadingStatus === "error") {
              return (location.pathname.includes('/upload-listing-images') ?
                <Redirect to={{
                  pathname: '/',
                  state: { from: currentLocation }
                }} /> : <Component {...props} />
              )
            }
          })()
        }
      </div>
    )} />
  );
};

export default PublicRoute;