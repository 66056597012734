/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import "./listing.css";
import {
    LOGO,
    CLOSEICON
} from "../../constants/imagepath";
import { useForm } from "react-hook-form";

// api function import from api services
import { referralRequestGetEquipmentOption, referralRequestGetSeaFoodOption, referralRequestLicenseOption, referralRequestMakeSellerRequest, referralRequestObjectFiltersNew } from '../../api_services/api_calls/referralAgentRequestApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';



import RefferalAgentRequestCompleted from './RefferalAgentRequestCompleted';
import Step1 from './ReferralRequestSteps/Step1';
import Step2 from './ReferralRequestSteps/Step2';
import Step3 from './ReferralRequestSteps/Step3';
import Step4 from './ReferralRequestSteps/Step4';
import Step5 from './ReferralRequestSteps/Step5';

const BuyerListing = (props) => {
    const { register, trigger, clearErrors, setError, reset, formState: { errors }, getValues, setValue, control, } = useForm(); // get react useform hook values


    // state for store and update the values of referral agent request
    const [step, setStep] = useState(0);
    const [userName, setUserName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [listingCompleted, setListingCompleted] = useState(false);
    const [boatAddress, setBoatAddress] = useState("");
    const [btnError, setBtnError] = useState("");
    const [provience, setProvince] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [latLng, setLatLng] = useState("");
    const [stepActive, setActiveStep] = useState(2);
    const [loader, setLoader] = useState(false);

    // state for store and update values of input fields 
    const [state, setState] = useState({
        BuyType: "Buy",
        ItemType: "Boat",
        VesselType: "Fishing",

        MinLengthFoot: "",
        MinLengthInch: "",
        MaxLengthFoot: "",
        MaxLengthInch: "",
        LengthUnit: "FT-IN",
        MinWidthFoot: "",
        MinWidthInch: "",
        MaxWidthFoot: "",
        MaxWidthInch: "",
        WidthUnit: "FT-IN",
        LicenseSpecies: "",
        LicenseRegion: "",
        LicenseArea: [],
        LicenseProvince: "",
        LicenseType: "",
        LicensePrice: "",
        LicenseOrQuota: 9,
        PleasureBoatName: "",
        PleasureClass: "",
        PleasureCategory: "",
        PleasureBoatPrice: "",
        EquipmentCategory: "",
        EquipmentProduct: "",
        EquipmentPrice: "",
        EquipmentCurrency: "",
        EquipmentManufacturer: "",
        EquipmentProvince: "",
        SeaFoodRegion: "",
        SeaFoodSpecies: "",
        SeaFoodSize: "",
        SeaFoodMinQuantity: "",
        SeaFoodMaxQuantity: "",
        SeaFoodProvince: "",
        SeaFoodCountry: "",
        SeaFoodPrice: "",
        SeaFoodCurrency: "",
        SeaFoodDescription: "",
        firstName: "",
        lastName: "",
        phoneNo: "",
        email: "",
        MinPriceWillingToPay: "",
        MaxPriceWillingToPay: "",
    });


    // state for store and update values of input fields 
    const [listingOptions, setListingOptions] = useState({
        constructionTypes: [],
        generalSpecies: [],
        brokerArea: [],
        brokerLicenseTypeGeneral: [],
        constructions: [],
        states: [],
        brokerEquipmentCategory: [],
        brokerEquipmentProduct: [],
        brokerEquipmentManufacturer: [],
        brokerGeneralSpecies: [],
        brokerRegion: [],
        brokerGeneralSpeciesSize: [],
    });


    /******* Calling Dynamic URL ********/
    // const apiUrl = process.env.REACT_APP_API_KEY;


    // useEffect for call the referral agent request Apis
    useEffect(() => {
        if (props.profileStatus) {
            setUserName(props.userData.profile.profile_data.first_name);
        }
        else {
            setUserName(props.userData.user.email);
        }
        referralRequestObjectFiltersNew() // api call via api services for request request object
            .then(function (response) {
                setListingOptions(listingOptions => ({
                    ...listingOptions,
                    ['constructions']: response?.data?.data?.constructions,
                    ['states']: response?.data?.data?.states,
                }))
            })
            .catch(function (error) {
                console.log(error);
            });
        referralRequestLicenseOption() // api call via api services for request license option
            .then(function (response1) {
                if (response1.data.status) {
                    setListingOptions(listingOptions => ({
                        ...listingOptions,
                        ['generalSpecies']: response1.data.data.generalSpecies,
                        ['brokerRegion']: response1.data.data.region,
                        ['brokerLicenseTypeGeneral']: response1.data.data.brokerLicenseTypeGeneral,
                    }))
                }
            })
            .catch(function (error) {
                console.log(error);
            });


        referralRequestGetEquipmentOption() // api call via api services for request get equipment option
            .then(function (response1) {
                if (response1.data.status) {
                    setListingOptions(listingOptions => ({
                        ...listingOptions,
                        ['brokerEquipmentCategory']: response1.data.data.brokerGearCategory,
                        ['brokerEquipmentProduct']: response1.data.data.brokerGearProduct,
                        ['brokerEquipmentManufacturer']: response1.data.data.brokerGearManufacturer,
                    }))
                }
            })
            .catch(function (error) {
                console.log(error);
            });


        referralRequestGetSeaFoodOption() // api call via api services for request get seafood option
            .then(function (response1) {
                if (response1.data.status) {
                    setListingOptions(listingOptions => ({
                        ...listingOptions,
                        ['brokerGeneralSpecies']: response1.data.data.brokerGeneralSpecies,
                        ['brokerGeneralSpeciesSize']: response1.data.data.brokerGeneralSpeciesSize,
                    }))
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        // eslint-disable-next-line
    }, []);


    // This function is triggered for set the validations step by step
    function triggerValidation(action) {
        const fields_arr = [
            {}, {},
            {
                fields: { User: ["firstName", "lastName", "phoneNo", "email", "MinPriceWillingToPay", "MaxPriceWillingToPay"] }
            },
            {
                fields: {
                    Boat: ["MinLengthFoot", "MinLengthInch", "MaxLengthFoot", "MaxLengthInch", "MinWidthFoot", "MinWidthInch", "MaxWidthFoot", "MaxWidthInch"],
                    License: ["LicenseNumber", "LicenseSpecies", "LicenseRegion", "LicenseArea", "LicenseProvince", "LicenseType", "MinPriceWillingToPay", "MaxPriceWillingToPay"],
                    Equipment: ["EquipmentManufacturer", "EquipmentCategory", "EquipmentProduct", "EquipmentProvince", "MinPriceWillingToPay", "MaxPriceWillingToPay"],
                    Seafood: ["SeaFoodRegion", "SeaFoodSpecies", "SeaFoodSize", "SeaFoodMinQuantity", 'SeaFoodMaxQuantity', "SeaFoodProvince", "SeaFoodCountry", "MinPriceWillingToPay", "MaxPriceWillingToPay"]
                }
            },
            { fields: { User: ["firstName", "lastName", "phoneNo", "email", "MinPriceWillingToPay", "MaxPriceWillingToPay"] } }
        ];
        if (step === 0) {
            if (state.BuyType === "") {
                setBtnError("Please select one of above");
                return false;
            }
            else {
                setBtnError("");
                setStep(step + 1);
            }
        }
        else if (step === 1) {
            if (state.ItemType === "") {
                setBtnError("Please select listing type");
                return false;
            }
            else {
                setBtnError("");
                setStep(step + 1);
            }
        }
        else if (step === 2) {
            // console.log('item_type', state.ItemType)
            if (state.ItemType === "Boat") {
                if (state.VesselType === "") {
                    setBtnError("Please select required fields");
                    return false;
                }
                else {
                    setBtnError("");
                    setStep(step + 1);
                }
            }
            else if (state.ItemType === "License" || state.ItemType === "Equipment" || state.ItemType === "Seafood") {
                trigger(fields_arr[step].fields['User']).then(valid => {
                    if (valid) if (action === 'next') { setStep(step + 1); } else { submitListing(); }
                });

            }
        }

        else if (step === 3) {
            if (state.ItemType === "Boat") {
                trigger(fields_arr[step].fields['Boat']).then(valid => {
                    if (valid) setStep(step + 1);
                });
            } else if (state.ItemType === "License") {
                trigger(fields_arr[step].fields['License']).then(valid => {
                    if (valid) if (action === 'next') { setStep(step + 1); } else { submitListing(); }
                });
            }
            else if (state.ItemType === "Equipment") {
                trigger(fields_arr[step].fields['Equipment']).then(valid => {
                    if (valid) if (action === 'next') { setStep(step + 1); } else { submitListing(); }
                });
            }
            else if (state.ItemType === "Seafood") {
                trigger(fields_arr[step].fields['Seafood']).then(valid => {
                    if (valid) if (action === 'next') { setStep(step + 1); } else { submitListing(); }
                });
            }

        } else if (step === 4) {
            trigger(fields_arr[step].fields['User']).then(valid => {
                if (valid) if (action === 'next') { setStep(step + 1); } else { submitListing(); }
            });
        }
        else {
            if (action === 'final') {
                submitListing();
            }
            return true;
        }

    }

    // this handle will triggered on click of next button
    function setNextStep(event) {
        event.preventDefault();
        if (Object.keys(errors).length === 0) {
            triggerValidation('next');
        }
    }


    // this handle will triggered on click of previous button
    function setPrevStep(e) {
        e.preventDefault();
        setBtnError("")
        setStep(step - 1);
        clearErrors();
    }

    // this handle will triggered when props change
    function handleChangeClick(event) {
        props.onChange(false);
    }

    // handle for highlight the active button
    function handleButtonSelect(evt) {
        evt.className += "active";
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
        setStep(step + 1);
        setBtnError("");
        reset()
    }

    // handle for manage values of the input field on Change function
    function handleButtonInput(evt) {
        evt.className += "active";
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
        //setNextStep();
    }

    // handle used for set values of Address like provience, country,postal code ,lat_lng etc.
    function handleAddress(data) {
        setBoatAddress(data.address);
        setProvince(data.state_id);
        setCountry(data.country_id);
        setPostalCode(data.postal_code);
        setLatLng(data.lat_lng);
    }

    // this handle is using for set the value of license areas
    function handleLicenseArea(data) {
        setListingOptions(listingOptions => ({
            ...listingOptions,
            ['brokerArea']: data,
        }))
    }
    function handleChange(data) {
        const value = data.target.value;
        let selectedOption = (data.target.value);
        if (data.target.name === "LicenseArea") {
            const selected = [];
            //var selectedOptionArr = Array(parseInt(selectedOption));
            //console.log('selectedOptionArr', selectedOptionArr)
            //for (let i = 0; i < selectedOptionArr.length; i++) {
            selected.push(selectedOption)
            //}
            setState({
                ...state,
                [data.target.name]: selected
            })
        }
        else {
            setState({
                ...state,
                [data.target.name]: value
            })
        }
        // focusOnElement(evt.target.id);
    }



    // this handle will triggered on submission of finish button and will check all the validations
    function submitBuyerListing(e) {
        e.preventDefault();
        //if(validationWithError())
        //{
        triggerValidation('final');
        // }
    }

    // this handle will set the data in states and updated with latest value
    function submitListing() {
        setSubmitting(true);
        var data = new FormData();
        if (state.BuyType === 'Sell') {
            data.append('buy_lease', '1');
        }
        else {
            data.append('buy_lease', '2');
        }

        if (state.ItemType === "Boat") {
            data.append('type', 'boat');
            data.append('item', state.ItemType);
            data.append('vessel_type', state.VesselType);
            data.append('min_length_foot', state.MinLengthFoot);
            data.append('min_length_inch', state.MinLengthInch);
            data.append('max_length_foot', state.MaxLengthFoot);
            data.append('max_length_inch', state.MaxLengthInch);
            data.append('length_unit', state.LengthUnit);

            data.append('min_width_foot', state.MinWidthFoot);
            data.append('min_width_inch', state.MinWidthInch);
            data.append('max_width_foot', state.MaxWidthFoot);
            data.append('max_width_inch', state.MaxWidthInch);
            data.append('width_unit', state.WidthUnit);



        } else if (state.ItemType === "License") {
            data.append('type', 'license');
            data.append('license_number', state.LicenseNumber); // License Number
            data.append('license_currency', 'USD');
            data.append('license_general_species', state.LicenseSpecies);
            data.append('region', state.LicenseRegion);
            for (let i = 0; i < state.LicenseArea.length; i++) {
                data.append('areas[]', state.LicenseArea[i])
            }
            data.append('license_province', state.LicenseProvince);
            data.append('license_type', state.LicenseType);
            data.append('sale_type_details', state.LicenseOrQuota);
        } else if (state.ItemType === "Equipment") {
            data.append('type', 'gear');
            data.append('gear_currency', 'USD');
            data.append('gear_category', state.EquipmentCategory);
            data.append('gear_Product_type', state.EquipmentProduct);
        } else if (state.ItemType === "Seafood") {
            data.append('type', 'seafood');
            data.append('seafood_currency', 'USD');
            data.append('seafood_region', state.SeaFoodRegion);
            data.append('seafood_general_species', state.SeaFoodSpecies);
            data.append('seafood_size', state.SeaFoodSize);
            data.append('seafood_min_quantity_lb', state.SeaFoodMinQuantity);
            data.append('seafood_max_quantity_lb', state.SeaFoodMaxQuantity);
            data.append('seafood_province', state.SeaFoodProvince);
            data.append('seafood_country', state.SeaFoodCountry);
        }
        data.append('first_name', state.firstName);
        data.append('last_name', state.lastName);
        data.append('phone', state.phoneNo);
        data.append('email', state.email);
        data.append('uploaded_by', 'referral');
        data.append('min_price', state.MinPriceWillingToPay);
        data.append('max_price', state.MaxPriceWillingToPay);

        setLoader(true)
        referralRequestMakeSellerRequest(data) // api call via api services for referral request of make seller request
            .then(function (response) {
                setLoader(false)

                if (response.data.status) {
                    setListingCompleted(true);
                }
                else {
                    toast.error('Something went wrong please try again later')
                    setSubmitting(false);
                }
                // handleChangeClick();
            })
            .catch(function (error) {
                console.log(error);
                setSubmitting(false);
            });
    }
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    return (
        <>

            <div id="popup1" className="overlay_2">
                <div className="popup1">
                    <div className="popup1-header">
                        <LazyLoadImage src={LOGO} alt="Logo" effect="blur" className='logoImg' />
                    </div>
                    <a className="close" onClick={handleChangeClick} href="javascript:void(0)"><img src={CLOSEICON} alt="close icon" /></a>
                    <section className='multi_step_form'>
                        <form id="msform">
                            {(() => {
                                return <ul id="buy_progressbar">
                                    {
                                        numbers.map((number) =>
                                            (<li className={stepActive >= number ? 'active' : ''}></li>)
                                        )}
                                </ul>;
                            })()}
                            <fieldset>
                                <div className="conten">
                                    {(listingCompleted) ? <RefferalAgentRequestCompleted stateData={state} listingOptions={listingOptions} boatAddress={boatAddress} /> :

                                        (step === 0) ? <Step1 userName={userName} values={state} handleFormData={handleButtonSelect} error={btnError} />
                                            : (step === 1) ? <Step2 values={state} handleFormData={handleButtonSelect} clearErrors={clearErrors} getValues={getValues} error={btnError} />
                                                : (step === 2) ? <Step3 values={state} handleFormData={handleChange} handleButtonInput={handleButtonInput} getValues={getValues} clearErrors={clearErrors} btnError={btnError} error={errors} register={register} selectValues={listingOptions} handleLicenseArea={handleLicenseArea} handleAddress={handleAddress} />
                                                    : (step === 3) ? <Step4 values={state} handleFormData={handleChange} register={register} error={errors} getValues={getValues} clearErrors={clearErrors} selectValues={listingOptions} handleLicenseArea={handleLicenseArea} handleAddress={handleAddress} setValue={setValue} control={control} />
                                                        : (step === 4) ? <Step5 values={state} handleFormData={handleChange} register={register} clearErrors={clearErrors} handleAddress={handleAddress} getValues={getValues} error={errors} setValue={setValue} control={control} />
                                                            : ""
                                    }
                                </div>

                                {(listingCompleted) ? <></> :
                                    <>
                                        {
                                            (step > 0) ?
                                                <button className="action-button previous_button" onClick={setPrevStep}>Back</button>
                                                : ''
                                        }


                                        {
                                            (state.ItemType !== 'Boat' && step === 3) ?
                                                ''
                                                :
                                                (step < 4) ?
                                                    <button className="next action-button" disabled={(submitting) ? true : false} onClick={setNextStep}>Next</button>
                                                    : ''
                                        }

                                        {loader ?
                                            <div className="loader"></div> : (state.ItemType === 'Boat' && step === 4) ? (
                                                <button className="next action-button buy_finish_btn" disabled={(submitting) ? true : false} onClick={submitBuyerListing}>Finish</button>

                                            ) : ((state.ItemType === 'License' ||
                                                state.ItemType === 'Pleasure Boat' ||
                                                state.ItemType === 'Seafood' ||
                                                state.ItemType === 'Equipment' ||
                                                state.ItemType === 'Package') &&
                                                step === 3) ? (
                                                <button
                                                    className="next action-button buy_finish_btn"
                                                    disabled={(submitting) ? true : false}
                                                    onClick={submitBuyerListing}>
                                                    Finish
                                                </button>
                                            ) : (
                                                ""
                                            )
                                        }

                                    </>
                                }

                            </fieldset>
                        </form>
                    </section>
                </div>
            </div>

        </>
    );
}

export default BuyerListing