import { Url } from "../api_settings/api_constants";
import { wrappedFetch, wrappedGet } from "../api_settings/urlFunctions";



//refferal Malke seller request
export const referralMakeSellerRequest = (data) => {
    return wrappedFetch(Url.REFERRAL_LISTING_MAKE_SELLER_REQUEST, "POST", data);
};


//referral object filters new
export const referralObjectFiltersNew = () => {
    return wrappedGet(Url.REFERRAL_LISTING_OBJECT_FILTERS_NEW, "GET");
};

//referral get license  options
export const referralGetLicenseOptions = () => {
    return wrappedGet(Url.REFERRAL_LISTING_GET_LICENSE_OPTION, "GET");
};

//referral pleasure boat
export const referralGetPleasureBoatOptions = () => {
    return wrappedGet(Url.REFERRAL_LISTING_GET_PLEASURE_BOAT_OPTION, "GET");
};


//referral get equipments
export const referralGetEquipmentOptions = () => {
    return wrappedGet(Url.REFERRAL_LISTING_GET_EQUIPMENT_OPTION, "GET");
};


//referral get seafood
export const referralGetSeaFoodOptions = () => {
    return wrappedGet(Url.REFERRAL_LISTING__GET_SEAFOOD_OPTION, "GET");
};