import React, { useState, useEffect, lazy } from "react";
import BotPressChatbot from "./BotPressChatBot";
import { SubscriptionModal } from "./Modals/SubscriptionModal";

const BotpresssContainer = (props) => {

   const [isVisibleSubscribeModal, setVisibleSubscribeModal] = React.useState(true);

   const handleSubscribeModal = (data) => {
    setVisibleSubscribeModal(data);
  };

  console.log('userData Props', props.userData);

   if(props.userData.has_membership === true)
    return (<div className="show-container"> <BotPressChatbot show={props.isShow}/></div>);
    else
    return <SubscriptionModal   
      visible={isVisibleSubscribeModal}
      onClose={handleSubscribeModal}
    />;
  
}

export default BotpresssContainer;