import React, {useState} from "react";
import { sellerImage, seller_video, sellerVideo } from "../../constants/imagepath";
function BuyIntroVideoModal({ userData,isIntroVideo, setIntroModalOpen, error }){
    return (

        <div>
           <p className="good_day_para">Good Day <strong>{userData.first_name}</strong>, please look into below video for your reference to create the request.</p>
          <center>
          <video
            autoPlay="autoplay"
            muted
            loop="loop"
            id="myVideo"
            preload="none"
            >
            <source src={seller_video} type="video/mp4" />
            <source src={sellerVideo} type="video/webm" />
            <source src={sellerImage} type="video/ogv" />
          </video>
          </center>
          {/* <button
            type="button"
            className="action-button previous previous_button"
            onClick={() => setIntroModalOpen(false)}
          >
            Skip
          </button>{" "} */}
          <button
            type="button"
            className="action-button previous previous_button"
            onClick={() => setIntroModalOpen(false)}
            style={{float:'right', backgroundColor: '#878787', color: '#ffffff'}}
          >
            Next
          </button>
        </div >
      );
}
export default BuyIntroVideoModal;