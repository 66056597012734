
import { useState, useEffect } from 'react';
import {
	Done
} from "../../constants/imagepath";

const RefferalAgentRequestCompleted = (props) => {
	const [licenseSpecies, setLicenseSpecies] = useState('');
	const [licenseArea, setLicenseArea] = useState('');
	const [licenseProvince, setLicenseProvince] = useState('');
	const [licenseType, setLicenseType] = useState('');
	const [licenseRegion, setLicenseRegion] = useState('');
	const [equipmentCategory, setEquipmentCategory] = useState('');
	const [equipmentProductType, setEquipmentProductType] = useState('');
	const [equipmentManufacturer, setEquipmentManufacturer] = useState('');
	const [equipmentProvince, setEquipmentProvince] = useState('');
	const [seafoodRegion, setSeafoodRegion] = useState('');
	const [seafoodSpecies, setSeafoodSpecies] = useState('');
	const [seafoodSize, setSeafoodSize] = useState('');


	const BuyerDetail = () => {
		return (
			<>

				<p className="your_data_has_been_text">Buyer Details</p>
				<table className="submitted_details">
					<tbody>
						<tr>
							<td className='official_number_text'>First Name</td>
							<td>:</td>
							<td className='right_text'>{props.stateData.firstName}</td>
						</tr>
						<tr>
							<td className='official_number_text'>Last Name</td>
							<td>:</td>

							<td className='right_text'>{props.stateData.lastName}</td>
						</tr>
						<tr>
							<td className='official_number_text' >Phone Number</td>
							<td>:</td>
							<td className='right_text'>{props.stateData.phoneNo}</td>
						</tr>
						<tr>
							<td className='official_number_text'>Email</td>
							<td>:</td>
							<td className='right_text'>{props.stateData.email}</td>
						</tr>
						<tr>
							<td className='official_number_text'>Location:</td>
							<td>:</td>
							<td className='right_text'>{props?.boatAddress}</td>
						</tr>
					</tbody>
				</table>
			</>
		);
	}
	useEffect(() => {
		setLicenseSpecies(getNameById(props.listingOptions.generalSpecies, props.stateData.LicenseSpecies));
		setLicenseArea(getNameById(props.listingOptions.brokerArea, props.stateData.LicenseArea));
		setLicenseProvince(getNameById(props.listingOptions.states, props.stateData.LicenseProvince));
		setLicenseType(getNameById(props.listingOptions.brokerLicenseTypeGeneral, props.stateData.LicenseType));
		setLicenseRegion(getNameById(props.listingOptions.brokerRegion, props.stateData.LicenseRegion));
		setEquipmentCategory(getNameById(props.listingOptions.brokerEquipmentCategory, props.stateData.EquipmentCategory));
		setEquipmentProductType(getNameById(props.listingOptions.brokerEquipmentProduct, props.stateData.EquipmentProduct));
		setEquipmentManufacturer(getNameById(props.listingOptions.brokerEquipmentManufacturer, props.stateData.EquipmentManufacturer));
		setEquipmentProvince(getNameById(props.listingOptions.states, props.stateData.EquipmentProvince));
		setSeafoodRegion(getNameById(props.listingOptions.brokerGeneralSpecies, props.stateData.SeaFoodRegion));
		setSeafoodSpecies(getNameById(props.listingOptions.brokerGeneralSpecies, props.stateData.SeaFoodSpecies));
		setSeafoodSize(getNameById(props.listingOptions.brokerGeneralSpeciesSize, props.stateData.SeaFoodSize));


		function getNameById(obj, id) {
			if (id > 0) {
				var responseData = Array.from(obj.filter(function (x) {
					return x.id === id
				}));
				return responseData[0].name;
			}
			return 'n/a';
		}
	});


	return (
		<>
			{(props?.stateData?.ItemType === 'Boat') ?

				<div>
					<center><img src={Done} className="submitted_icon" /></center>
					<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
					<p className="following_text">Following Are The Details Which You Have Submitted</p>
					<table className="submitted_details">
						<tbody>
							<tr>
								<td className='official_number_text'>Dimensions(Length)</td>
								<td>:</td>
								<td className='right_text'>{props.stateData.MinLengthFoot}'{props.stateData.MinLengthInch}"&nbsp;-&nbsp;{props.stateData.MaxLengthFoot}'{props.stateData.MaxLengthInch}" {props.stateData.LengthUnit === 'MT-CM' ? '(Meters)' : '(Feets)'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Dimensions(Width)</td>
								<td>:</td>
								<td className='right_text'>{props.stateData.MinWidthFoot}'{props.stateData.MinWidthInch}"&nbsp;-&nbsp;{props.stateData.MaxWidthFoot}'{props.stateData.MaxWidthInch}" {props.stateData.WidthUnit === 'MT-CM' ? '(Meters)' : '(Feets)'}</td>
							</tr>

						</tbody>
					</table>
					<BuyerDetail />

				</div>
				:
				(props.stateData.ItemType === 'License' || props.stateData.ItemType === 'Package' || props.stateData.ItemType === 'Quota')
					?
					<div>
						<center><img src={Done} className="submitted_icon" /></center>
						<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
						<p className="following_text">Following Are The Details Which You Have Submitted</p>
						<table className="submitted_details">
							<tbody>
								<tr>
									<td className='official_number_text'>License Region</td>
									<td>:</td>
									<td className='right_text'>{licenseRegion ? licenseRegion : 'N/A'}</td>
								</tr>
								<tr>
									<td className='official_number_text'>License Species</td>
									<td>:</td>
									<td className='right_text'>{licenseSpecies ? licenseSpecies : 'N/A'}</td>
								</tr>
								<tr>
									<td className='official_number_text'>License Area</td>
									<td>:</td>
									<td className='right_text'>{licenseArea ? licenseArea : 'N/A'}</td>
								</tr>
								<tr>
									<td className='official_number_text'>Province</td>
									<td>:</td>
									<td className='right_text'>{licenseProvince ? licenseProvince : 'N/A'}</td>
								</tr>
								<tr>
									<td className='official_number_text'>License Type</td>
									<td>:</td>
									<td className='right_text'>{licenseType ? licenseType : 'N/A'}</td>
								</tr>
								<tr>
									<td className='official_number_text'>Price</td>
									<td>:</td>
									<td className='right_text'>${props.stateData.MinPriceWillingToPay ? props.stateData.MinPriceWillingToPay : 'N/A'} - ${props.stateData.MaxPriceWillingToPay ? props.stateData.MaxPriceWillingToPay : 'N/A'}</td>
								</tr>
							</tbody>
						</table>

						<BuyerDetail />
					</div>

					:
					(props.stateData.ItemType === 'Equipment')
						?
						<div>
							<center><img src={Done} className="submitted_icon" /></center>
							<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
							<p className="following_text">Following Are The Details Which You Have Submitted</p>
							<table className="submitted_details">
								<tbody>
									<tr>
										<td className='official_number_text'>Category</td>
										<td>:</td>
										<td className='right_text'>{equipmentCategory ? equipmentCategory : 'N/A'}</td>

									</tr>
									<tr>
										<td className='official_number_text'>Product Type</td>
										<td>:</td>
										<td className='right_text'>{equipmentProductType ? equipmentProductType : 'N/A'}</td>

									</tr>
									<tr>
										<td className='official_number_text'>Manufacturer</td>
										<td>:</td>
										<td className='right_text'>{equipmentManufacturer ? equipmentManufacturer : 'N/A'}</td>
									</tr>
									<tr>
										<td className='official_number_text'>Province</td>
										<td>:</td>
										<td className='right_text'>{equipmentProvince ? equipmentProvince : 'N/A'}</td>
									</tr>

									<tr>
										<td className='official_number_text'>Price</td>
										<td>:</td>
										<td className='right_text'>${props.stateData.MinPriceWillingToPay ? props.stateData.MinPriceWillingToPay : 'N/A'} - ${props.stateData.MaxPriceWillingToPay ? props.stateData.MaxPriceWillingToPay : 'N/A'}</td>
									</tr>
								</tbody>
							</table>
							<BuyerDetail />
						</div> :
						(props.stateData.ItemType === 'Seafood')

							?
							<div>
								<center><img src={Done} className="submitted_icon" /></center>
								<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
								<p className="following_text">Following Are The Details Which You Have Submitted</p>
								{/* <LazyLoadImage src={Done} alt='Search' effect='blur' style={{ width: "100px", marginTop: "100px" }} /> */}
								<table className="submitted_details">
									<tbody>
										<tr>
											<td className='official_number_text'>Region</td>
											<td>:</td>
											<td className='right_text'>{seafoodRegion ? seafoodRegion : 'N/A'}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Species</td>
											<td>:</td>
											<td className='right_text'>{seafoodSpecies ? seafoodSpecies : 'N/A'}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Size</td>
											<td>:</td>
											<td className='right_text'>{seafoodSize ? seafoodSize : 'N/A'}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Min Quantity</td>
											<td>:</td>
											<td className='right_text'>{props.stateData.SeaFoodMinQuantity ? props.stateData.SeaFoodMinQuantity : 'N/A'}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Max Quantity</td>
											<td>:</td>
											<td className='right_text'>{props.stateData.SeaFoodMaxQuantity ? props.stateData.SeaFoodMaxQuantity : 'N/A'}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Price</td>
											<td>:</td>
											<td className='right_text'>${props.stateData.MinPriceWillingToPay ? props.stateData.MinPriceWillingToPay : "N/A"} - ${props.stateData.MaxPriceWillingToPay ? props.stateData.MaxPriceWillingToPay : 'N/A'}</td>
										</tr>
									</tbody>
								</table>
								<BuyerDetail />
							</div>
							:
							<div className="text-center">
								<div className="container">
									<div className="row">
										<div className="col-md-12">
											<h2></h2>
										</div>
									</div>
								</div>
							</div>
			}
		</>
	);
}

export default RefferalAgentRequestCompleted