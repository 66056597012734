import axios from 'axios';
import { useState, useEffect, useMemo, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { userSelector } from "../../store/userSlice";
import "./SellerListing.css";
import { deleteFavoriteListing, getFavoritesListing } from '../../api_services/api_calls/listingObjectApi';
import { toast } from 'react-toastify';
import '../../components/Listing/listing.css'
// import { makeStyles } from '@material-ui/core/styles';
import AfterLoginHeader from '../../components/header/AfterLoginHeader';
import { DELETEFAVORITE } from '../../constants/imagepath';
import Skeleton from 'react-loading-skeleton';
import Footer from '../../components/footer/Footer';

const ConfirmationModal = lazy(() => import("../../components/Modals/ConfirmationModal"));

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     margin: theme.spacing(2),
//   },
//   absolute: {
//     position: 'absolute',
//     bottom: theme.spacing(2),
//     right: theme.spacing(3),
//   },
// }));

function FavoriteListing() {

  // const classes = useStyles();

  /******* Calling Dynamic URL ********/
  const apiUrl = process.env.REACT_APP_API_KEY;
  const { userData } = useSelector(userSelector);  // "Getting user data via redux(useSelector)"
  const history = useHistory();

  // "States for manage component data"
  const [listings, setListings] = useState([]);
  const [listingCount, setListingCount] = useState(1);
  const [links, setLinks] = useState([])
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState([]);
  const [filterType, setFilterType] = useState("Boat");
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [fetchPage, setFetchPage] = useState(false);


  const handlePaginate = (event) => { // "handle for pagination"
    axios.get(event,
      { headers: { 'Accept': 'application/json', "Authorization": `Bearer ${localStorage.getItem('token')}` } })
      .then(function (response2) {
        var listingResponse = response2.data;
        setListingCount(listingResponse.total);
        setListings(listingResponse.data.data);
        setLinks(listingResponse.data.links)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function listingEdit(id) { // "Handle for redirect user on edit seller listin"
    history.push('/edit-seller-listing/' + id);
  }

  const deleteFavorite = (listing) => { // "handle for delete particular listing"
    console.log(listing)
    setConfirmModal(true)
    setSelectedListing(listing)
  }

  // useEffect(() => {  // useEffect will run after delete listing and we will fetch page data
  //   handlePaginate(`${apiUrl}get-listing-test?type=${filterType}&page=${page}`);
  //   // eslint-disable-next-line
  // }, [fetchPage]);


  const isDeleteConfirm = (data) => { // "api function for delete particular seller listing"
    console.log(data)
    setLoader(true);
    deleteFavoriteListing(data.id)
      .then(response => {
        setLoader(false)
       // setFetchPage(!fetchPage)
        setSelectedListing([])

        // window.location.reload();
        toast.success(response.data.message)
         setListings(listings.filter((item, i) => item.id !== data.id));
        setConfirmModal(false)
      })
      .catch(function () {
        setConfirmModal(false)
      });
  }


  function listingDetail(id) { // "Handle for redirect user on view seller request"
    history.push('/view-seller-listing/' + id);
  }




  function handleFilterChange(event) { // handle for set filter of seller listing
    setFilterType(event.target.value);
    if (filterType !== event.target.value) {
      setPage(1);
    }
  }

  const getList = () => {
    getFavoritesListing() // "api function for et listing data via api services
      .then(function (response2) {
        setLoader(false);
        var listingResponse = response2.data;
        setListingCount(listingResponse.total);
        setListings(listingResponse.data.data);
        setLinks(listingResponse.data.links)
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  useEffect(() => { // "function for get listing type of seller listing"
    setLoader(true);
    // var config2 = {
    //   method: 'get',
    //   url: apiUrl + 'get-listing-test?type=Boat',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //   },
    // };

    // axios(config2)
    getList();
    // eslint-disable-next-line
  }, []);

  function getBackgroundColor(data) {
    if (data?.notifications) {
      if (data?.notifications?.type === "seller_listing_delete") {
        return "table-danger";
      } else if (data?.notifications?.type === "seller_listing_sold") {
        return "table-warning";
      } else {
        return;
      }
    } else if (data?.is_active === "active") {
      return "table-success";
    } else {
      return;
    }
  }

  return (
    <>
      <AfterLoginHeader userData={userData} />
      <section className="section ct_section_top_180_mob top-0 ct_vertical_h_on_big_screen ct_verticle_h_100vh pt-4 mt-5" style={{ backgroundColor: '#F9F9F9' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="tell_us_about_yourself_title">YOUR FAVORITES</h3>
            </div>
          </div>
          <div className="sell_listing_box">

            <div className="list-wrapper">

              <div className="list-item">
                <section className="section3">


                  <div className="flex-container">
                    {/* <!-- <a href=""type="button" name="dang ky">Registration</a> --> */}
                    <div className="member table-responsive">
                      <ConfirmationModal
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        deleteConfirm={isDeleteConfirm}
                        listingId={selectedListing?.id}
                        type="favorite"
                      />
                      {loader ?
                        <Skeleton count={7.7} />
                        :
                        <table className="content-member lising_table">
                          <thead>
                            <tr className="name-row" >
                              <th>S.No.</th>
                              <th>Package Type</th>
                              <th>Listing Number</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (listingCount > 0) ? listings.map((list, index) => (
                                <tr key={index} className={getBackgroundColor(list)}>
                                  <td className="edit" key={index}>{index + 1}</td>
                                  <td className="edit">{(list.packagetype2) ? list.packagetype2.name : '--'}</td>
                                  <td className="edit">{list.listing_number}</td>
                                  <td className="edit">${list.package_price.toFixed(2)}</td>
                                  <td className="edit ct_edit_td_action">
                                    {/* <button type="button" name="save" className="save-member"><img src={EDITLISTING} /></button> */}
                                    {(() => {
                                      if (list?.notifications?.type === "seller_listing_sold") {
                                        return ''
                                      }
                                      else if (list?.notifications?.type === "seller_listing_delete") {
                                        return ''
                                      }
                                      else {
                                        return (
                                          <button type="button" name="delete" className="delete-member" onClick={() => deleteFavorite(list)}><img src={DELETEFAVORITE} alt="delete favorite" width="50" /></button>

                                          // <a style={{ cursor: 'pointer' }} className='mr-3' title='delete' onClick={() => listingDelete(list)}><FontAwesomeIcon icon={faTrash} /></a>
                                        )
                                      }
                                    })()}
                                  </td>
                                </tr>
                              ))
                                : <tr>No Data Found</tr>
                            }
                          </tbody>
                        </table>
                      }
                    </div>
                    {
                      (listingCount > 0) &&
                      <div className='col-md-12 text-center mt-2 pagination-div'>
                        {
                          links.map((event, index) => {
                            return (
                              <button key={index} className={event.active ? 'mr-1 mt-1 btn btn-outline-dark pagination-btn active' : 'mr-1 mt-1 btn btn-outline-dark pagination-btn'} value={event?.url}
                                onClick={(e) => {
                                  setPage(index);
                                  handlePaginate(e.target.value);
                                }}
                              >
                                {(event.label === "&laquo; Previous") ? '<<' : (event.label === "Next &raquo;") ? '>>' : event.label}
                              </button>
                            )
                          })
                        }

                      </div>
                    }
                  </div>
                </section>
              </div>

            </div>


            {/* <div id="pagination-container">   </div> */}

          </div>
        </div>
        </section>
        <Footer />

      <ConfirmationModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        deleteConfirm={isDeleteConfirm}
        listingId={selectedListing?.id}
        type="favorite"
      />

    </>
  );
}

export default FavoriteListing