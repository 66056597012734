import React, { useState } from "react";
import { CLOSECIRCLE, ARROWBACK } from "../../constants/imagepath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

// "Import components via react-lazy load"
// const MapContainer = lazy(() => import("./MapContainer"));
// const DropDownMenu = lazy(() => import("./DropdownMenu"));

function BecomeReferralAgentModal({ isOpen, setModalOpen, onConfirm }) {
  const dispatch = useDispatch();

  // const putContentInDropDown = (value) => {
  //   return value;
  // };
  return (

    <div>
      <center>
        <video
          width="100%"
          height="240"
          controls
          class="popup_video hidden-sm-down"
        >
          <source src="movie.mp4" type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </center>
      <button
        type="button"
        className="action-button previous previous_button"
        onClick={() => setModalOpen(false)}
      >
        Skip
      </button>{" "}
      <button
        type="button"
        className="action-button previous previous_button"
        onClick={onConfirm}
      >
        Confirm
      </button>
    </div >
  );
}

export default BecomeReferralAgentModal;
