import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  getBuyerPackage,
} from "../api_services/api_calls/memberShipApis";
import UnSubscribeModal from "../components/Modals/unSubscribeModal";
import { CIRCLECHECKMARK } from "../constants/imagepath";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Footer from "../components/footer/Footer";

export default function MembershipContent(props) {
  const [packages, setPackages] = useState([]);
  const [packageId, setPackageId] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [apiCalling, setApiCalling] = useState(true);
  const has_membership = localStorage.getItem("has_membership");
  // console.log('selectedPlan mambership===>', selectedPlan)
  /******* Calling Dynamic URL ********/
  // const apiUrl = process.env.REACT_APP_API_KEY;
  const location = useLocation(); // "function to get current path name"

  const handleToggle = (packageId) => {
    // handle to manage pop up for particular id
    setConfirmModal(true);
    setSelectedPlan(packageId);
  };
  useEffect(() => {
    // "Function for get user  and get buyer package it will triggered ehren usercomes first time on page or refresh the page"
    getBuyerPackage() // Api function for get Buyer  package via api services
      .then((response) => {
        setPackages(response.data.data);
        setApiCalling(false);
      })
      .catch((error) => {
        setApiCalling(false);
      });


  }, []);

  if (apiCalling) {
    return <h2 className="text-center">Loading, Please wait ....</h2>;
  } else {
    return (
      <>
        {packages.length > 0 ? (
          <>
            {packages.map((item, index) => {
              let month = parseInt(item.package_duration_in_days / 30);
              return (
                <>
                  {(() => {
                    // if (item?.has_purchased === null) {
                    return (
                      <div
                        className="col-xs-12 col-md-6 col-lg-4 mb-4 ct_price_table_count"
                        key={index}
                      >
                        <div
                          className={`pricing-table pricing-table_${index + 2
                            }`}
                        >
                          {item.best_deal ? (
                            <div class="cover-ribbon">
                              <div class="cover-ribbon-inside">Best Deal</div>
                            </div>
                          ) : ""}
                          <div className={`package-title_${index + 2}`}>
                            <p className="months_text">
                              {month < 6
                                ? "MONTHLY"
                                : month >= 6 && month < 12
                                  ? "HALF YEARLY"
                                  : "YEARLY"}{" "}
                              PACKAGE
                            </p>
                            <span className="ct_year_to_year_bold">
                              {month < 6
                                ? "MONTH TO MONTH"
                                : month >= 6 && month < 12
                                  ? "EVERY 6 MONTHS"
                                  : "YEAR TO YEAR"}
                            </span>
                          </div>
                          <div className="package-layout">
                            {!selectedPlan.length && has_membership === null && (
                              <span style={{ color: 'red', fontWeight: '700' }}>Free Trial of 2 hours</span>
                            )}

                            <p className="basic_package_text" key={item}>
                              {item.name}
                            </p>
                            <div className="currency">
                              <div className="package-currency">$</div>
                              <div className="package-value">
                                <div className="value">
                                  {item.monthly_price}
                                </div>
                                <div className="period">
                                  /{month === 1} Month
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="divider"></div>
                          <div className="terms">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                            <div className="divider"></div>
                            <p className="hst_text">
                              (${item.monthly_price} +HST) For{" "}
                              {month < 6
                                ? "Monthly"
                                : month >= 6 && month < 12
                                  ? "6 Months"
                                  : "Yearly"}{" "}
                              <br />${item.price}+HST
                            </p>
                            <Link
                              to={{
                                pathname:
                                  props.packageId != item.id
                                    ? `/package/` + item.id
                                    : location.pathname,
                              }}
                              className={
                                "card-btn mt-auto text-center" +
                                (props.packageId > 0 && props.packageId == item.id
                                  ? "disable-payment-btn"
                                  : "")
                              }
                            >
                              <button
                                className={(props.packageId > 0 && props.packageId == item.id) ? `get_started_btn` : `get_started_btn_3`}
                              >
                                {props.packageId > 0 && props.packageId == item.id
                                  ? "ACTIVATED"
                                  : "GET STARTED"}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                    // }
                  })()}
                  <UnSubscribeModal
                    confirmModal={confirmModal}
                    setConfirmModal={setConfirmModal}
                    selectedPlan={selectedPlan}
                    handleToggle={handleToggle}
                  />
                </>
              );
            })}

          </>
        ) : (
          <Skeleton count={3.5} />
        )}
      </>
    );
  }
}
