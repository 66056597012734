import { createSlice } from "@reduxjs/toolkit";

const chatData = { // "initial state for chat" 
	chatStatus: false,
	currentChat: 'help',
	widgetId: process.env.REACT_APP_CLIENT_WIDGET,
	chatCnt: 0,
	chatProduct: 0
}


const chatSlice = createSlice({ // "Chat slice for for chat"
	name: 'chat',
	initialState: chatData,
	reducers: {
		toggleChatStatus(state) {
			state.chatStatus = !state.chatStatus;
		},
		expandChat(state) {
			state.chatStatus = true;
		},
		closeChat(state) {
			state.chatStatus = false;
		},
		changeChatStatus(state, action) {
			state.chatStatus = action.payload;
		},
		changeCurrentChat(state, action) {
			state.currentChat = action.payload;
		},
		changeChatCount(state, action) {
			state.chatCnt = action.payload;
		},
		changeWidgetId(state, action) {
			state.widgetId = action.payload;
		},
		changeChatProduct(state, action) {
			state.chatProduct = action.payload;
		}
	}
});

export const chatActions = chatSlice.actions;  // "Exporting chat Actions"

export default chatSlice;