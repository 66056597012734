import { configureStore } from "@reduxjs/toolkit";
import chatSlice from './chat-slice';
import userSlice from './userSlice';
import productSlice from './product-slice';
import listingReducer from './listingSlice';
import filterSlice from "./filterSlice";
import referralListingSlice from "./referralListingSlice";

const store = configureStore({ // "here we combine all reducers"
	reducer: {
		chat: chatSlice.reducer,
		user: userSlice,
		product: productSlice.reducer,
		listing: listingReducer,
		user: userSlice,
		filter: filterSlice,
		referralListing:referralListingSlice
	}
});

export default store;