import { createSlice } from "@reduxjs/toolkit";

const productData = {
  // Initial state for product Data
  listView: false,
  productObj: null,
};

const productSlice = createSlice({
  // Slice for product
  name: "product",
  initialState: productData,
  reducers: {
    // Reducers
    toggleListView(state) {
      state.listView = !state.listView;
    },
    openModalPopup(state, { payload }) {
      state.productObj = payload;
    },
  },
});

export const productActions = productSlice.actions;

export default productSlice;
