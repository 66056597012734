import MembershipContent from "../../screens/MembershipContent";
import {
    CLOSEICON
} from "../../constants/imagepath";
import React, { useState, useEffect, lazy } from "react";

export const SubscriptionModal = ({ visible, onClose }) => {

    console.log('visiblity', visible)
    const closePackageModal = () => {
        onClose(!visible);
    };
    return (
        <>
            {visible ? (

                <div id="productPopup" className="overlay_2">
                    <div className="popup1 full-width-popup">
                        <div className="row">
                            <div className="popup-header custom-close-btn px-5 py-3">
                                <a className="close" href="javascript:void(0)">
                                    <img
                                        alt="closeIcon"
                                        src={CLOSEICON}
                                        onClick={closePackageModal}
                                    />
                                </a>
                            </div>
                            <div class="row p-4">
                                <MembershipContent />
                            </div>
                        </div>
                    </div>
                </div>)
                :
                ''}
        </>
    );
}
