// import {axiosInstance} from "./axiosConfig";
import axios from "./axiosConfig";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function wrappedFetch(url, method, data, headers) { //  "Function for call POST api"
  let config = {
    url,
    method,
    headers: {},
    data,
  };
  if (headers) config.headers = headers;
  try {
    return await axios(config);
  } catch (err) {
    toast.error('Something went wrong please try again after sometime');
    return err
  }
}
export async function wrappedGet(url, method, params) { // "Function fir call GET api , delete, put api"
  let config = {
    url,
    method,
    headers: {},
    params
  };
  try {
    return await axios(config);
  } catch (err) {
    toast.error('Something went wrong try please again after sometime');
    return err
  }
}
