import { Controller } from "react-hook-form";
import SearchSelectCreate from "../../selectSearch/SearchSelectCreate";

const Step6 = ({
  values,
  handleFormData,
  register,
  clearErrors,
  getValues,
  error,
  selectValues,
  setValue,
  control,
}) => {
  // console.log('step6', error)
  return (
    <>
      <p className="good_day_para">
        Please Select The Construction Material:
        <span className="required-field"></span>
      </p>
      <div className="row">
        <div className="col-md-12">
          <span className="popup_input_label">Construction Material</span>
        </div>
      </div>
      <div className="combo-select-dropdown">
        <Controller
          name="ConstructionMetarial"
          control={control}
          defaultValue=""
          rules={{ required: "This field is required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <SearchSelectCreate
              defaultValue={
                selectValues.constructions.find(
                  (e) => e.id === getValues("ConstructionMetarial")
                )
                  ? {
                    value: selectValues.constructions.find(
                      (e) => e.id === getValues("ConstructionMetarial")
                    ).id,
                    label: selectValues.constructions.find(
                      (e) => e.id === getValues("ConstructionMetarial")
                    )?.name,
                  }
                  : {
                    value: getValues("ConstructionMetarial"),
                    label: getValues("ConstructionMetarial"),
                  }
              }
              onChange={(data, action) => {
                setValue("ConstructionMetarial", data.value);
                handleFormData({
                  target: {
                    value: data.value,
                    name: "ConstructionMetarial",
                  },
                });

                clearErrors("ConstructionMetarial");
              }}
              options={selectValues?.constructions?.map((consts, index) => {
                return { value: consts.id, label: consts.name };
              })}
            />
          )}
        />

        {error.ConstructionMetarial && (
          <span className="err-msg">{error.ConstructionMetarial.message}</span>
        )}
      </div>
    </>
  );
};

export default Step6;
