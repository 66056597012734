import { useState, useEffect } from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
	Done
} from "../../constants/imagepath";



const BuyerListingCompleted = (props) => {

	const [constructionMet, setConstructionMet] = useState('');
	const [licenseSpecies, setLicenseSpecies] = useState('');
	const [licenseRegion, setLicenseRegion] = useState('');
	const [licenseArea, setLicenseArea] = useState('');
	const [licenseProvince, setLicenseProvince] = useState('');
	const [licenseType, setLicenseType] = useState('');
	const [equipmentCategory, setEquipmentCategory] = useState('');
	const [equipmentProductType, setEquipmentProductType] = useState('');
	const [equipmentManufacturer, setEquipmentManufacturer] = useState('');
	const [equipmentProvince, setEquipmentProvince] = useState('');
	const [seafoodRegion, setSeafoodRegion] = useState('');
	const [seafoodSpecies, setSeafoodSpecies] = useState('');
	const [seafoodSize, setSeafoodSize] = useState('');


	useEffect(() => {
		var licenseAreas = getMatchedData(props.listingOptions.brokerArea, props.stateData.LicenseArea);
		setConstructionMet(getNameById(props.listingOptions.constructions, props.stateData.ConstructionMetarial));
		setLicenseRegion(getNameById(props.listingOptions.brokerRegion, props.stateData.LicenseRegion));
		setLicenseSpecies(getNameById(props.listingOptions.generalSpecies, props.stateData.LicenseSpecies));
		setLicenseArea(licenseAreas);
		setLicenseProvince(getNameById(props.listingOptions.states, props.stateData.LicenseProvince));
		setLicenseType(getNameById(props.listingOptions.brokerLicenseTypeGeneral, props.stateData.LicenseType));
		setEquipmentCategory(getNameById(props.listingOptions.brokerEquipmentCategory, props.stateData.EquipmentCategory));
		setEquipmentProductType(getNameById(props.listingOptions.brokerEquipmentProduct, props.stateData.EquipmentProduct));
		setEquipmentManufacturer(getNameById(props.listingOptions.brokerEquipmentManufacturer, props.stateData.EquipmentManufacturer));
		setEquipmentProvince(getNameById(props.listingOptions.states, props.stateData.EquipmentProvince));
		setSeafoodRegion(getNameById(props.listingOptions.brokerGeneralSpecies, props.stateData.SeaFoodRegion));
		setSeafoodSpecies(getNameById(props.listingOptions.brokerGeneralSpecies, props.stateData.SeaFoodSpecies));
		setSeafoodSize(getNameById(props.listingOptions.brokerGeneralSpeciesSize, props.stateData.SeaFoodSize));
		// eslint-disable-next-line
	}, []);

	function getNameById(obj, id) {
		if (id > 0) {
			var responseData = Array.from(obj.filter(function (x) {
				return x.id === id
			}));
			return responseData[0].name;
		}
		return 'n/a';
	}


	function getMatchedData(array1, array2) {
		let arr2 = array2.map(Number);
		const html = array1.filter(object1 => {
			return arr2.some(object2 => {
				return object1.id === object2;
			});
		}).map(e => {
			return e.name;
		}).join('<br/>');
		return <p dangerouslySetInnerHTML={{ __html: html }}></p>
	}

	return (
		<>
			{(props.stateData.ItemType === 'Boat') ?

				<div>
					<center><img src={Done} className="submitted_icon" alt="Submit icon" /></center>
					<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
					<p className="following_text">Following Are The Details Which You Have Submitted</p>
					<table className="submitted_details">
						<tbody>
							<tr>
								<td className='official_number_text'>Buy/ Lease</td>
								<td className='right_text'>{props.stateData.BuyType}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Item</td>
								<td className='right_text'>{props.stateData.ItemType}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Type of Vessel</td>
								<td className='right_text'>{props.stateData.VesselType}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Dimensions Min & Max Length</td>
								<td className='right_text'>Min Length: {props.stateData.MinLengthFoot} {props.stateData.LengthUnit === 'MT-CM' ? 'Mt' : 'ft'} {props.stateData.MinLengthInch} {props.stateData.LengthUnit === 'MT-CM' ? 'Cm' : 'In'} ||
									Max Length: {props.stateData.MaxLengthFoot} {props.stateData.LengthUnit === 'MT-CM' ? 'Mt' : 'ft'}  {props.stateData.MaxLengthInch} {props.stateData.LengthUnit === 'MT-CM' ? 'Cm' : 'In'} </td>
							</tr>
							<tr>
								<td className='official_number_text'>Dimensions Min & Max Width</td>
								<td className='right_text'>Min Width: {props.stateData.MinWidthFoot} {props.stateData.WidthUnit === 'MT-CM' ? 'Mt' : 'ft'}  {props.stateData.MinWidthInch} {props.stateData.WidthUnit === 'MT-CM' ? 'Cm' : 'In'} ||
									Max Width:{props.stateData.MaxWidthFoot} {props.stateData.WidthUnit === 'MT-CM' ? 'Mt' : 'ft'} {props.stateData.MaxWidthInch} {props.stateData.WidthUnit === 'MT-CM' ? 'Cm' : 'In'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Gross Tonnage</td>
								<td className='right_text'>{props.stateData.MinGrossTonnage} - {props.stateData.MaxGrossTonnage}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Construction</td>
								<td className='right_text'>{constructionMet}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Price</td>
								<td className='right_text'>${props.stateData.MinPriceWillingToPay} - ${props.stateData.MaxPriceWillingToPay}</td>
							</tr>
							<tr>
								<td className='official_number_text' th>Plan To Buy</td>
								<td className='right_text'>Within {props.stateData.BuyPlan}</td>
							</tr>
						</tbody>
					</table>
				</div>
				:
				(props.stateData.ItemType === 'License')
					?
					<div>
						<center><img src={Done} className="submitted_icon" alt="submit icon" /></center>
						<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
						<p className="following_text">Following Are The Details Which You Have Submitted</p>
						<table className="submitted_details">
							<tbody>
								<tr>
									<td className='official_number_text'>License Region</td>
									<td className='right_text'>{licenseRegion}</td>
								</tr>
								<tr>
									<td className='official_number_text'>License Species</td>
									<td className='right_text'>{licenseSpecies}</td>
								</tr>

								<tr>
									<td className='official_number_text'>License Area</td>
									<td className='right_text'>{licenseArea}</td>
								</tr>
								<tr>
									<td className='official_number_text'>Province</td>
									<td className='right_text'>{licenseProvince}</td>
								</tr>
								<tr>
									<td className='official_number_text' th>Price</td>
									<td className='right_text'>${props.stateData.MinPriceWillingToPay} - ${props.stateData.MaxPriceWillingToPay}</td>
								</tr>
							</tbody>
						</table>
					</div>
					:
					(props.stateData.ItemType === 'Quota')
						?
						<div>
							<center><img src={Done} className="submitted_icon" alt="submit icon" /></center>
							<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
							<p className="following_text">Following Are The Details Which You Have Submitted</p>
							<table className="submitted_details">
								<tbody>
									<tr>
										<td className='official_number_text'>License Region</td>
										<td className='right_text'>{licenseRegion}</td>
									</tr>
									<tr>
										<td className='official_number_text'>License Species</td>
										<td className='right_text'>{licenseSpecies}</td>
									</tr>

									<tr>
										<td className='official_number_text'>License Area</td>
										<td className='right_text'>{licenseArea}</td>
									</tr>
									<tr>
										<td className='official_number_text'>Province</td>
										<td className='right_text'>{licenseProvince}</td>
									</tr>

									<tr>
										<td className='official_number_text' th>Price</td>
										<td className='right_text'>${props.stateData.MinPriceWillingToPay} - ${props.stateData.MaxPriceWillingToPay}</td>
									</tr>
								</tbody>
							</table>
						</div>
						:
						(props.stateData.ItemType === 'Equipment')
							?
							<div>
								<center><img src={Done} className="submitted_icon" alt="submit icon" /></center>
								<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
								<p className="following_text">Following Are The Details Which You Have Submitted</p>
								<table className="submitted_details">
									<tbody>
										<tr>
											<td className='official_number_text'>Category</td>
											<td className='right_text'>{equipmentCategory}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Product Type</td>
											<td className='right_text'>{equipmentProductType}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Manufacturer</td>
											<td className='right_text'>{equipmentManufacturer}</td>
										</tr>

										<tr>
											<td className='official_number_text'>Province</td>
											<td className='right_text'>{equipmentProvince}</td>
										</tr>

										<tr>
											<td className='official_number_text' th>Price</td>
											<td className='right_text'>${props.stateData.MinPriceWillingToPay} - ${props.stateData.MaxPriceWillingToPay}</td>
										</tr>
									</tbody>
								</table>
							</div>
							:
							(props.stateData.ItemType === 'Seafood')
								?
								<div>
									<center><img src={Done} className="submitted_icon" alt="submit icon" /></center>
									<p className="buy_your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
									<p className="following_text">Following Are The Details Which You Have Submitted</p>
									<table className="submitted_details">
										<tbody>

											<tr>
												<td className='official_number_text'>Species</td>
												<td className='right_text'>{seafoodSpecies}</td>
											</tr>
											<tr>
												<td className='official_number_text'>Size</td>
												<td className='right_text'>{seafoodSize}</td>
											</tr>

											<tr>
												<td className='official_number_text'>Min Quantity</td>
												<td className='right_text'>{props.stateData.SeaFoodMinQuantity}</td>
											</tr>
											<tr>
												<td className='official_number_text'>Max Quantity</td>
												<td className='right_text'>{props.stateData.SeaFoodMaxQuantity}</td>
											</tr>

											<tr>
												<td className='official_number_text' th>Price</td>
												<td className='right_text'> ${props.stateData.MinPriceWillingToPay} - ${props.stateData.MaxPriceWillingToPay}</td>
											</tr>
										</tbody>
									</table>
								</div>
								:
								<div className="text-center">
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<h2></h2>
											</div>
										</div>
									</div>
								</div>
			}
		</>
	);
}

export default BuyerListingCompleted