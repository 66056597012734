import { CLOSEICON } from "../../constants/imagepath";

import "react-toastify/dist/ReactToastify.css";

export default function InformationModal({ infoModal, setShowInfoModal }) {
  const handleCloseButton = () => {
    setShowInfoModal();
  };
  return (
    <>
      {infoModal && (
        <div id="info_popup" className="overlay_2">
          <div className="popup1 bg-transparent">
            <div className="popup-header custom-close-btn h-auto ct_custom_close_1">
              <a className="close" href="javascript:void(0)">
                <img
                  alt="closeIcon"
                  src={CLOSEICON}
                  onClick={handleCloseButton}
                />
              </a>
            </div>

            {/* <p className="signup_and_experience_para">
               Signup And Experience The Benefits Of Novi Marine Brokers
             </p> */}
            <div class="container">
              <div class="row">
                <div class="col-12 px-0">
                  <iframe
                    src="https://mediafiles.botpress.cloud/2d9d16ac-235f-406d-a15f-ffbab5eb1bab/webchat/bot.html"
                    width="600"
                    height="400"
                    style={{ border: "none" }}
                    title="Botpress Chatbot"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
