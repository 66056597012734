import React from "react";
import { useState, useEffect } from 'react';

const Step4 = ({ handleFormData, values, register, error, selectValues, clearErrors }) => {

    const [placeHolderValue, setPlaceholderValue] = useState('FT-IN'); // state for store the place holder value input fields
    const [checklength, setCheckLength] = useState('') // state for store the data of min and max length


    // useEffect for compare min length and max length of the vessel
    useEffect(() => {
        const minTotalCm = values?.MinLengthFoot * 30.48 + values?.MinLengthInch * 2.54;
        const maxTotalCm = values?.MaxLengthFoot * 30.48 + values?.MaxLengthInch * 2.54;

        if (minTotalCm >= maxTotalCm) {
            return setCheckLength('Minimum length should not be greater or equal maximum length');
        } else {
            return setCheckLength('');
        }
    }, [values?.MinLengthFoot, values?.MinLengthInch, values?.MaxLengthFoot, values?.MaxLengthInch])

    return (
        <>
            <p className="good_day_para">Please Provide The Basic Vessel Measurements</p>
            <span className="popup_input_label">What Is The Minimum Length:<span className="required-field"></span></span>
            <div className="measurements">
                <label for="input1" className="input1">
                    <input id="input1" type="text" name="MinLengthFoot" defaultValue={values?.MinLengthFoot} placeholder="Ex: 80"   {...register("MinLengthFoot",
                        {
                            required: "This field is required.",
                            validate: {
                                required: (value) => {
                                    if (parseFloat(value) <= 0) {
                                        return 'Min length should be greater than zero.'
                                    }
                                    if (checklength?.length !== 0) {
                                        return checklength;
                                    }
                                }
                            },
                        }

                    )}
                        onChange={(e) => {
                            clearErrors("MinLengthFoot")
                            handleFormData(e)
                        }} /><span>Feets</span>
                </label>
                <label for="input1" className="input1">
                    <input id="input1" type="text" placeholder="Ex: 10" name="MinLengthInch"
                        defaultValue={values?.MinLengthInch}  {...register("MinLengthInch",
                            {
                                required: "This field is required.",
                                validate: {
                                    required: (value) => {
                                        if (parseFloat(value) <= 0) {
                                            return 'Min inch should be greater than zero.'
                                        }
                                    }
                                },
                                validate: {
                                    maxNumber: (value) => {
                                        if (placeHolderValue === 'FT-IN') {
                                            if (value > 11) {
                                                return "Inch should not be greater than 11"
                                            }
                                        } else {
                                            if (value > 99) {
                                                return "Cm should not be greater than 99"
                                            }
                                        }
                                    }
                                }
                            }
                        )}
                        onChange={(e) => {
                            clearErrors("MinLengthInch")
                            handleFormData(e)
                        }} /><span>Inches</span>
                </label>
                <div className="select-dropdown">
                    <div className="dropdown">
                        <select className="custom-select mr-sm-2 col-md-12" id="inlineFormCustomSelect" onChange={(e) => { handleFormData(e); setPlaceholderValue(e.target.value) }} value={selectValues.LengthUnit} name="LengthUnit">
                            <option value="FT-IN" selected={true}>Feet-Inches</option>
                            <option value="MT-CM">Meter-Cm</option>
                        </select>
                    </div>
                </div>
            </div>
            {(error?.MinLengthFoot || error?.MinLengthInch) && <span className="err-msg position-relative" style={{ top: "-10px" }} >{error.MinLengthFoot ? error?.MinLengthFoot?.message : error?.MinLengthInch?.message}</span>}
            <span className="popup_input_label">What Is The Maximum Length:<span className="required-field"></span></span>
            <div className="measurements">
                <label for="input1" className="input1">
                    <input id="input1" type="text" placeholder="Ex: 60" name="MaxLengthFoot" defaultValue={values.MaxLengthFoot} {...register("MaxLengthFoot", { required: "This field is required.", validate: { required: (value) => { if (parseFloat(value) <= 0) return 'Min length should be greater than zero.' } } })}
                        onChange={(e) => {
                            clearErrors("MaxLengthFoot")
                            handleFormData(e)
                        }} /><span>Feets</span>
                </label>
                <label for="input1" className="input1">
                    <input id="input1" type="text" placeholder="Ex: 07" name="MaxLengthInch"
                        defaultValue={values.MaxLengthInch}  {...register("MaxLengthInch",
                            {
                                required: "This field is required.", validate: {
                                    maxNumber: (value) => {
                                        if (placeHolderValue === 'FT-IN') {
                                            if (value > 12) {
                                                return "Inch should not be greater than 11"
                                            }
                                        } else {
                                            if (value > 99) {
                                                return "Cm should not be greater than 99"
                                            }
                                        }
                                    }
                                }
                            }
                        )}
                        onChange={(e) => {
                            clearErrors("MaxLengthInch")
                            handleFormData(e)
                        }} /><span>Inches</span>
                </label>
                <div className="select-dropdown">
                    <div className="dropdown">
                        <select className="custom-select mr-sm-2 col-md-12" id="inlineFormCustomSelect" onChange={(e) => { handleFormData(e); setPlaceholderValue(e.target.value) }} value={selectValues.LengthUnit} name="LengthUnit">
                            <option value="FT-IN" selected={true}>Feet-Inches</option>
                            <option value="MT-CM">Meter-Cm</option>
                        </select>
                    </div>
                </div>
            </div>
            {(error?.MaxLengthFoot || error?.MaxLengthInch) && <span className="err-msg position-relative" style={{ top: "-10px" }}>{error?.MaxLengthFoot ? error?.MaxLengthFoot?.message : error?.MaxLengthInch.message}</span>}
            <span className="popup_input_label">What Is The Minimum Width:<span className="required-field"></span></span>
            <div className="measurements">
                <label for="input1" className="input1">
                    <input id="input1" type="text" placeholder="Ex: 15" name="MinWidthFoot" defaultValue={values?.MinWidthFoot}   {...register("MinWidthFoot",
                        {
                            required: "This field is required.",
                            validate: {
                                required: (value) => {
                                    if (parseFloat(value) <= 0) {
                                        return 'Min length should be greater than zero.'
                                    }
                                    if (checklength?.length !== 0) {
                                        return checklength;
                                    }

                                }
                            }
                        })}
                        onChange={(e) => {
                            clearErrors("MinWidthFoot")
                            handleFormData(e)
                        }} /><span>Feets</span>
                </label>
                <label for="input1" className="input1">
                    <input id="input1" type="text" placeholder="Ex: 10" name="MinWidthInch" defaultValue={values?.MinWidthInch} {...register("MinWidthInch",
                        {
                            required: "This field is required.",
                            validate: {
                                maxNumber: (value) => {
                                    if (placeHolderValue === 'FT-IN') {
                                        if (parseFloat(value) > 11) {
                                            return "Inch should not be greater than 11"
                                        }
                                    } else {
                                        if (parseFloat(value) > 99) {
                                            return "Cm should not be greater than 99"
                                        }
                                    }
                                }
                            }
                        }
                    )}
                        onChange={(e) => {
                            clearErrors("MinWidthInch")
                            handleFormData(e)
                        }} /><span>Inches</span>
                </label>
                <div className="select-dropdown">
                    <div className="dropdown">
                        <select className="custom-select mr-sm-2 col-md-12" id="inlineFormCustomSelect" onChange={(e) => { handleFormData(e); setPlaceholderValue(e.target.value) }} value={selectValues.LengthUnit} name="LengthUnit">
                            <option value="FT-IN" selected={true}>Feet-Inches</option>
                            <option value="MT-CM">Meter-Cm</option>
                        </select>
                    </div>
                </div>
            </div>
            {(error.MinWidthFoot || error.MinWidthInch) && <span className="err-msg position-relative" style={{ top: "-10px" }} >{error.MinWidthFoot ? error.MinWidthFoot.message : error.MinWidthInch.message}</span>}
            <span className="popup_input_label">What Is The Maximum Width:<span className="required-field"></span></span>
            <div className="measurements">
                <label for="input1" className="input1">
                    <input id="input1" type="text" placeholder="Ex: 15" name="MaxWidthFoot" defaultValue={values.MaxWidthFoot} {...register("MaxWidthFoot", { required: "This field is required.", validate: { required: (value) => { if (parseFloat(value) <= 0) return 'Min length should be greater than zero.' } } })}
                        onChange={(e) => {
                            clearErrors("MaxWidthFoot")
                            handleFormData(e)
                        }} /><span>Feets</span>
                </label>
                <label for="input1" className="input1">
                    <input id="input1" type="text" placeholder="Ex: 10" name="MaxWidthInch" defaultValue={values.MaxWidthInch} {...register("MaxWidthInch",
                        {
                            required: "This field is required.",
                            validate: {
                                maxNumber: (value) => {
                                    if (placeHolderValue === 'FT-IN') {
                                        if (parseFloat(value) > 11) {
                                            return "Inch should not be greater than 11"
                                        }
                                    } else {
                                        if (parseFloat(value) > 99) {
                                            return "Cm should not be greater than 99"
                                        }
                                    }
                                }
                            }
                        }
                    )}
                        onChange={(e) => {
                            clearErrors("MaxWidthInch")
                            handleFormData(e)
                        }} /><span>Inches</span>
                </label>
                <div className="select-dropdown">
                    <div className="dropdown">
                        <select className="custom-select mr-sm-2 col-md-12" id="inlineFormCustomSelect" onChange={(e) => { handleFormData(e); setPlaceholderValue(e.target.value) }} value={selectValues.LengthUnit} name="LengthUnit">
                            <option value="FT-IN" selected={true}>Feet-Inches</option>
                            <option value="MT-CM">Meter-Cm</option>
                        </select>
                    </div>
                </div>
            </div>
            {(error.MaxWidthFoot || error.MaxWidthInch) && <span className="err-msg position-relative" style={{ top: "-10px" }}>{error.MaxWidthFoot ? error.MaxWidthFoot.message : error.MaxWidthInch.message}</span>}
        </>
    );
}

export default Step4;
