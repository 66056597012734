import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import React, { Suspense, lazy } from "react";

import "./assets/css/bootstrap.css";
import "./assets/css/style.css";

import "./assets/css/responsive.css";

import "./assets/css/style_2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AddUserDetails from "./screens/AddUserDetails/AddUserDetails";
import SimpleMap from "./components/GoogleMap/SimpleMap";
import FavoriteListing from "./screens/seller-listing/FavoriteListing";
import Example from "./components/Example";
import Page from "./screens/Page";
import EditRequestObjectPhotos from "./screens/buyer-request/EditRequestObjectPhotos";
import job from "./components/job";

const Home = lazy(() => import("./screens/Home"));

// const Dashboard = lazy(() => import("./screens/Dashboard"));
const TestDashBoard = lazy(() => import("./screens/test-dashboard/Dashboard"));

const UploadImages = lazy(() => import("./screens/ImagesUpload"));
const Membership = lazy(() => import("./screens/Membership"));
const BillingHistory = lazy(() => import("./screens/BillingHistory"));
const UserProfile = lazy(() => import("./screens/AddUserDetails/UserProfile"));
const SellerListing = lazy(() =>
  import("./screens/seller-listing/SellerListing")
);
const EditObject = lazy(() => import("./screens/seller-listing/EditObject"));
const EditObjectPhotos = lazy(() =>
  import("./screens/seller-listing/EditObjectPhotos")
);
const SellDashboard = lazy(() => import("./screens/SellDashboard"));
const Dashboard = lazy(() => import("./screens/Dashboard"));

const EditSellerListing = lazy(() =>
  import("./screens/seller-listing/EditSellerListing")
);

const ViewObject = lazy(() => import("./screens/seller-listing/ViewObject"));

const ViewSellerListing = lazy(() =>
  import("./screens/seller-listing/ViewSellerListing")
);

// const ChatBot = lazy(() => import("./components/ChatBot"))

// const BuyerReuest = lazy(() => import("./screens/buyer-request/BuyerRequest"));

// const ChatBot = lazy(() => import("./components/ChatBot"));*/
const BuyerReuest = lazy(() => import("./screens/buyer-request/BuyerRequest"));
const ViewBuyerRequest = lazy(() =>
  import("./screens/buyer-request/ViewBuyerRequest")
);
const EditBuyerRequest = lazy(() =>
  import("./screens/buyer-request/EditBuyerRequest")
);
const EditRequestObject = lazy(() =>
  import("./screens/buyer-request/EditObject")
);

/*const EditBuyerRequest = lazy(() => import("./screens/buyer-request/EditBuyerRequest"));
const EditRequestObject = lazy(() => import("./screens/buyer-request/EditObject"));*/

const StripMenu = lazy(() => import("./components/strip/StripMenu"));
const ThankYou = lazy(() => import("./components/strip/ThankYou"));
const CustomSearchSelect = lazy(() =>
  import("./components/CustomSearchSelect")
);

const Client = lazy(() => import("./components/commet-chat/Client"));
const Agent = lazy(() => import("./components/commet-chat/Agent"));
const ContactUsBroker = lazy(() =>
  import("./components/commet-chat/ContactUsBroker")
);
const ContactUsSeller = lazy(() =>
  import("./components/commet-chat/ContactUsSeller")
);
//const ChatTest = lazy(() => import("./components/commet-chat/ChatTest"));
const Pagenotfound = lazy(() => import("./screens/Errors/pageNotFound"));
const SiteOverview = lazy(() => import("./screens/SiteOverview/SiteOverview"));

const Suggestion = lazy(() => import("./screens/Suggestion"));
const App = () => {
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div
              style={{
                //position: "absolute",
                marginTop: "25%",
                //left: "25%",
                fontSize: "150px",
              }}
              className="text-center"
            >
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          }
        >
          <Switch>
            <PublicRoute path="/" exact component={Home} />
            <PublicRoute path="/example" exact component={Example} />
            <PrivateRoute path="/simplemap" exact component={SimpleMap} />

            {/*<PublicRoute path="/search" element={<Search />} />
            <PublicRoute path="/signup" component={SignUp} />
            */}
            <PrivateRoute
              path="/sell-dashboard"
              exact
              component={SellDashboard}
            />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute
              path="/test-dashboard"
              exact
              component={TestDashBoard}
            />

            <PrivateRoute
              path="/upload-listing-images/:id"
              component={UploadImages}
            />
            <PrivateRoute path="/membership" exact component={Membership} />
            <PrivateRoute
              path="/billing-history"
              exact
              component={BillingHistory}
            />
            {/*<PrivateRoute path="/subscriptions" exact component={Subscriptions} />*/}

            <PrivateRoute
              path="/site_overview"
              exact
              component={SiteOverview}
            />

            <PrivateRoute
              path="/userDetails"
              exact
              component={AddUserDetails}
            />

            <PrivateRoute path="/profile" exact component={UserProfile} />
            <PrivateRoute path="/suggestion" exact component={Suggestion} />
            <PrivateRoute
              path="/seller-listing"
              exact
              component={SellerListing}
            />

            <PrivateRoute path="/favorites" exact component={FavoriteListing} />

            {/* <PrivateRoute path="/add-seller-listing" exact component={AddSellerListing} /> */}
            <PrivateRoute
              path="/edit-object/:id"
              exact
              component={EditObject}
            />
            <PrivateRoute
              path="/edit-object-photos/:id"
              exact
              component={EditObjectPhotos}
            />
            <PrivateRoute
              path="/edit-request-object-photos/:id"
              exact
              component={EditRequestObjectPhotos}
            />
            <PrivateRoute
              path="/edit-seller-listing/:id"
              exact
              component={EditSellerListing}
            />
            <PrivateRoute path="/view-object/*" exact component={ViewObject} />
            <PrivateRoute
              path="/view-seller-listing/*"
              exact
              component={ViewSellerListing}
            />
            {/* <PrivateRoute path="/custom-chatbot/*" exact component={ChatBot} /> */}
            <PrivateRoute path="/your-requests" exact component={BuyerReuest} />
            <PrivateRoute
              path="/view-buyer-request/*"
              exact
              component={ViewBuyerRequest}
            />
            <PrivateRoute path="/job" exact component={job} />
            <PrivateRoute
              path="/edit-request/:id"
              exact
              component={EditBuyerRequest}
            />
            <PrivateRoute
              path="/edit-request-object/:id"
              exact
              component={EditRequestObject}
            />
            <PrivateRoute path="/package/:id" exact component={StripMenu} />
            <PrivateRoute path="/thankyou" exact component={ThankYou} />
            <PrivateRoute
              path="/custom-searchselect"
              exact
              component={CustomSearchSelect}
            />

            {/*
            <Route path="/search" component={ChatBot} />*/}
            <PrivateRoute path="/client" exact component={Client} />
            <PrivateRoute path="/agent" exact component={Agent} />
            <PrivateRoute
              path="/contact-us-broker"
              exact
              component={ContactUsBroker}
            />
            <PrivateRoute
              path="/contact-us-seller/:id"
              exact
              component={ContactUsSeller}
            />
            <PublicRoute path="/:slug" component={Page} />
            <PrivateRoute path="*" exact component={Pagenotfound} />

            {/* <Route path="*" component={Pagenotfound} /> */}
          </Switch>
          <ToastContainer autoClose={2000} />
          {/*{window.location.pathname !== "/mapView" ? <Footer /> : <> </>}
        {window.location.pathname !== '/sell-dashboard/mapView' && <Footer /> }*/}
        </Suspense>
      </Router>
    </>
  );
};

export default App;
