import { applySearch } from "../api_services/api_calls/autoSearchSuggestionApi";
import { useDispatch } from "react-redux";
import { filterActions } from "../store/filterSlice";

const AutoSearchSuggestions = (props) => {
  const dispatch = useDispatch();
  const productData = props.searchData;
  // const [suggestionsData, setSuggestionsData] = useState([]);
  function capitalizeFirstLetter(string) {
    // "Function for capatlize the first letter in search section"
    return string.charAt(0).toUpperCase() + string.slice(1);
  }




  function handleChange(searchType, searchId, searchQuery) {
    // "Function fir search listing on the basis of id, type etc."
    var searchFormat = [];
    searchFormat["searchType"] = searchType;
    searchFormat["searchId"] = searchId;
    searchFormat["clicked"] = true;
    searchFormat["search_query"] = searchQuery;
    props.onChange(searchFormat);
    dispatch(filterActions.handleSearchFilterData(true));

    applySearch({ type: searchType, id: searchId }) // "Api function for apply search listing with type and id"
      .then(function (response) {
        // setSuggestionsData(response?.data?.data);
        dispatch(filterActions.handleSearchFilterData(false));
        props.parentToChild(response?.data);
        localStorage.setItem(
          "searchfilter",
          JSON.stringify(response?.data.search_filters_id)
        );
        const sessionTime = Date.now() + 2 * (60 * 60 * 1000);
        localStorage.setItem("sessionTimes", sessionTime);
        dispatch(
          filterActions.updataSearchData({
            listing_type: response?.data?.search_filters.listing_type,
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className="search-suggestions" id="search-suggestions">
        {productData != null ? (
          productData.data.map((product, index) =>
            // <p>{product.type}</p>

            product.total > 0 ? (
              <>
                <p>
                  {" "}
                  <b>
                    -----
                    {capitalizeFirstLetter(
                      String(product.type).replace(/_/g, " ")
                    )}
                    ------
                  </b>
                </p>
                {product.result.map((product1, index1) => (
                  <>
                    <div
                      className="suggestion-list"
                      onClick={() => {
                        handleChange(product.type, product1.id, product1.name);
                      }}
                    >
                      <p>{product1.name}</p>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <></>
            )
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AutoSearchSuggestions;
