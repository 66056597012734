import React from "react";

const Step11 = ({ handleFormData, values, error }) => {
    return (
        <>
            <p className="good_day_para mt-2">When Do You Plan To Buy</p>
            <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="BuyPlan" value="3 months" id="plan_buy1-button" onClick={handleFormData} checked={values.BuyPlan === '3 months' ? 'checked' : ''} />
                <label className="button-label button-label_3" for="plan_buy1-button">
                    <h1>Within 3 Months</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="BuyPlan" value="6 months" id="plan_buy2-button" onClick={handleFormData} checked={values.BuyPlan === '6 months' ? 'checked' : ''} />
                <label className="button-label button-label_3" for="plan_buy2-button">
                    <h1>Within 6 Months</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="BuyPlan" value="12 months" id="plan_buy3-button" onClick={handleFormData} checked={values.BuyPlan === '12 months' ? 'checked' : ''} />
                <label className="button-label button-label_3" for="plan_buy3-button">
                    <h1>Within 12 Months</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="BuyPlan" value="over 12 months" id="plan_buy4-button" onClick={handleFormData} checked={values.BuyPlan === 'over 12 months' ? 'checked' : ''} />
                <label className="button-label button-label_3" for="plan_buy4-button">
                    <h1>Over 12 Months</h1>
                </label>
            </div>
            {error && values.BuyPlan === '' ? <p className='err-msg'>Please select one of above</p> : ''}
        </>
    );


}

export default Step11;
