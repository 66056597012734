
import axios from "axios";
// import { logout } from "../../store/userSlice";
import { getAccessToken } from "../api_calls/authApi";
import createHistory from 'history/createBrowserHistory';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



// "component for implement interceptor which helps us to make api services" 
const FetchClient = () => {

    let Instance = axios.create({ // "Function for creaate instance for interceptor and it will identify the status for Apis"
        // baseURL: API_URL,
        validateStatus: (status) => {
            return (
                (status >= 200 && status <= 204) ||
                status === 401 ||
                status === 400 ||
                status === 409
            );
        },
    });

    Instance.interceptors.request.use(  // "Function for set the token dynamically in all the apis after login"
        config => {
            const token = getAccessToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            config.headers['Content-Type'] = 'application/json';
            return config;
        });


    Instance.interceptors.response.use((response) => { // "Function for identify the token if its invalid it will loout the user automatically "
        if (response.status === 401 && response.statusText === "Unauthorized") {
            var token = getAccessToken();
            if (token != null) {
                localStorage.clear();
                toast.error('Your session has been expired please login again to continue')
                const history = createHistory()
                history.replace("/")
                window.location.reload();
            }
        }
        try {
            return response;
        } catch (err) {
            toast.error('Something went wrong try again after sometime');
        }
    });

    return Instance;
}

export default FetchClient();

