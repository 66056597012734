import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../store/userSlice";

import UnSubscribeModal from "../components/Modals/unSubscribeModal";
import dateFormat from "dateformat";
// import { CIRCLECHECKMARK } from "../constants/imagepath";
import AfterLoginHeader from "../components/header/AfterLoginHeader";
import Footer from "../components/footer/Footer";
import {
  getContent
} from "../api_services/api_calls/contentPageApi";

export default function Membership(props) {
  const { userData } = useSelector(userSelector);
  // const [packages, setPackages] = useState([]);
  const [packageId, setPackageId] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [nextBillingDate, setBillingDate] = useState();
  const [currentPackagePrice, setCurrentPackagePrice] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [packageStatus, setPackageStatus] = useState();
  // const [packageActive, setPackageActive] = useState();
  const [isSubscribed, setIsSubscribed] = useState();

  /******* Calling Dynamic URL ********/
  // const apiUrl = process.env.REACT_APP_API_KEY;

  const handleToggle = (packageId) => {
    // handle to manage pop up for particular id
    setConfirmModal(true);
    setSelectedPlan(packageId);
  };
  // const [loader, setLoader] = useState(false);
  // Fetch data based on the slug, or use a static mapping
  const [pageTitle, setPageTitle] = useState("");
  const [pageContent, setPageContent] = useState("");



  return (
    <>

      <AfterLoginHeader userData={userData} />
      <section className="package_section ct_px_0 pt-5">
        <div className="container">
          <div className="row">
            <div className="ct_job_tab_btns">
              <button className="save_changes_btn py-2">Job Wanted</button>
              <button className="save_changes_btn py-2">Job Offered</button>

            </div>
            <div className="col-md-12 text-end mb-3">
              <div className="d-flex align-items-center gap-2 justify-content-between">
              <div className="ct_job_search">
                  <input type="text" placeholder="Search Job" className="form-control mb-0"/>
                  <i className="fa fa-search"></i>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div>
                <select name="" id="" className="form-control ct_skill_select mb-0">
                  <option value="">Job Type</option>
                  <option value="">HTML5</option>
                  <option value="">Php</option>
                  <option value="">React</option>
                  <option value="">Angular</option>
                  <option value="">Python</option>
                </select>
                </div>
                <div>
                <button className="save_changes_btn mb-0 py-2"  data-bs-toggle="modal" data-bs-target="#addnewjob">+ Add New Job</button>

                </div>
              </div>

              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="ct_job_card">
                <h6>Account</h6>
                <h4>UI Designer</h4>
               <div className="ct_job_skill">
               <span>HTML5</span>
               <span>Css3</span>
               <span>Javascript</span>
               <span>jQuery</span>
               </div>
                <ul>
                  <li>
                    2d ago
                  </li>
                  <li>
                    Part Time
                  </li>
                  <li>USA Only</li>
                </ul>


              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="ct_job_card">
                <h6>Account</h6>
                <h4>UI Designer</h4>
               <div className="ct_job_skill">
               <span>HTML5</span>
               <span>Css3</span>
               <span>Javascript</span>
               <span>jQuery</span>
               </div>
                <ul>
                  <li>
                    2d ago
                  </li>
                  <li>
                    Part Time
                  </li>
                  <li>USA Only</li>
                </ul>


              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="ct_job_card">
                <h6>Account</h6>
                <h4>UI Designer</h4>
               <div className="ct_job_skill">
               <span>HTML5</span>
               <span>Css3</span>
               <span>Javascript</span>
               <span>jQuery</span>
               </div>
                <ul>
                  <li>
                    2d ago
                  </li>
                  <li>
                    Part Time
                  </li>
                  <li>USA Only</li>
                </ul>


              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="ct_job_card">
                <h6>Account</h6>
                <h4>UI Designer</h4>
               <div className="ct_job_skill">
               <span>HTML5</span>
               <span>Css3</span>
               <span>Javascript</span>
               <span>jQuery</span>
               </div>
                <ul>
                  <li>
                    2d ago
                  </li>
                  <li>
                    Part Time
                  </li>
                  <li>USA Only</li>
                </ul>


              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="ct_job_card">
                <h6>Account</h6>
                <h4>UI Designer</h4>
               <div className="ct_job_skill">
               <span>HTML5</span>
               <span>Css3</span>
               <span>Javascript</span>
               <span>jQuery</span>
               </div>
                <ul>
                  <li>
                    2d ago
                  </li>
                  <li>
                    Part Time
                  </li>
                  <li>USA Only</li>
                </ul>


              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="ct_job_card">
                <h6>Account</h6>
                <h4>UI Designer</h4>
               <div className="ct_job_skill">
               <span>HTML5</span>
               <span>Css3</span>
               <span>Javascript</span>
               <span>jQuery</span>
               </div>
                <ul>
                  <li>
                    2d ago
                  </li>
                  <li>
                    Part Time
                  </li>
                  <li>USA Only</li>
                </ul>


              </div>

            </div>

          </div>
          
        </div>
      </section>
      <div class="modal fade" id="addnewjob" tabindex="-1" aria-labelledby="addnewjobLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header  border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div className="ct_job_form">
          <form action="">
            <h4 className="text-center">Add New Job</h4>
            <div className="row mt-4">
              <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Title</label>
                    <input type="text" className="form-control" placeholder="Enter Job Title" />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Type</label>
                    <select className="form-control">
                      <option value="">Part Time</option>
                      <option value="">Full Time</option>
                    </select>
                    </div>

                    <div className="form-group mb-3">
                    <label htmlFor="" className="ct_fw_600 ct_fs_16">Job Location</label>
                    <select className="form-control">
                      <option value="">USA</option>
                      <option value="">India</option>
                    </select>
                    </div>

              </div>

            </div>
          </form>

        </div>
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="save_changes_btn">Save changes</button>
      </div>
    </div>
  </div>
</div>
      <Footer />

     
    </>
  );
}
