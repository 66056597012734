import React from "react";

const Step1 = ({ userData, handleFormData, referralStatus, values, error }) => {
  console.log('userData', userData)
  return (
    <>
      <p className="good_day_para">
        Good Day <strong>{userData.first_name}</strong>, Please Advise Us,Are
        You Looking To Sell An Item Today Or Are You Looking To Lease Item Out?
      </p>
      <div className="button-wrap">
        <input
          className="hidden radio-label"
          type="radio"
          name="SellType"
          value="Lease"
          id="yes-button"
          checked={values.SellType === "Lease" ? "checked" : ""}
          onClick={handleFormData}
        />
        <label className="button-label ct_btn_width_242" for="yes-button">
          <h1>Lease</h1>
        </label>
        <input
          className="hidden radio-label"
          type="radio"
          name="SellType"
          value="Sell"
          id="no-button"
          checked={values.SellType === "Sell" ? "checked" : ""}
          onClick={handleFormData}
        />
        <label className="button-label ct_btn_width_242" for="no-button">
          <h1>Sell</h1>
        </label>

        {referralStatus === null ? (
          <>
            <input
              className="hidden radio-label"
              type="radio"
              name="become_referral_agent"
              value="1"
              id="referral_agent_button"
              onClick={handleFormData}
            />
            <label className="button-label ct_btn_width_242" for="referral_agent_button">
              <h1>Become Referral Agent Now</h1>
            </label>
          </>
        ) : referralStatus === "pending" ? (
          <label className="button-label ct_btn_width_242" for="referral_agent_button">
            <h1>Your request is pending</h1>
          </label>
        ) : (
          ""
        )}
      </div>
      {error && <p className="err-msg">Please select one of above</p>}
    </>
  );
};

export default Step1;
