import axios from 'axios';
import { useState, useEffect } from 'react';
import { Progress } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom';
import { faSpinner, faVideo } from '@fortawesome/free-solid-svg-icons'
import heic2any from "heic2any";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { userSelector } from '../../store/userSlice';
import '../../components/Listing/listing.css'
import AfterLoginHeader from '../../components/header/AfterLoginHeader';
import { UPLOADICONIMAGE } from '../../constants/imagepath';
import {
    // buyerRequestDelete,
    requestDeleteObjectPic,
    getRequestObjectPhotos
  } from "../../api_services/api_calls/buyerListingObjectApi";
  import Footer from '../../components/footer/Footer';

// "import component via react lazy loading"
function EditRequestObjectPhotos(props) {



    /******* Calling Dynamic URL ********/
    const apiUrl = process.env.REACT_APP_API_KEY;
    const { userData } = useSelector(userSelector);  // getting user data from redux(useSelector)
    let { id } = useParams();
    const allowExtensions = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', '.heic', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/webm',]; // "Allowed extensions for upload the pice and videos"
    // "states for manage component data" 
    const [error, setError] = useState("");
    const [respMsg, setRespMsg] = useState("");
    const [imgloading, setImgLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [objectPhotos, setObjectPhotos] = useState([]);
    const [objectVideos, setObjectVideos] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedImgs, setSelectedImgs] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [progressValue, setProgress] = useState(0)
    // const [complete, setComplete] = useState(false)
    // const [completedCount, setCompletedCount] = useState(0);
    // console.log('progressValue===>', progressValue)
    // console.log('completedCount===>', completedCount)


    // Just some styles
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
        },
        preview: {
            marginTop: 50,
            display: "flex",
            flexDirection: "column",
        },
        image: { maxWidth: "100%", maxHeight: 320 },
        delete: {
            cursor: "pointer",
            background: "red",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "27px",
            position: "absolute",
            right: "18px"
        },
        displayImage: {
            border: "1px solid grey",
            margin: "5px 2px 2px 2px"
        },

    };

    function savePhotos() { // "We are using this function to call upload images api on click of upload button"
        setLoader(true)
        setUploading(true);
        if (selectedImage.length > 0) {
            setSubmitting(true)
            var data = new FormData();
            for (let i = 0; i < selectedImage.length; i++) {
                data.append('files[]', selectedImage[i])
            }
            var config = {
                method: 'post',
                url: apiUrl + 'request/add-object-photo/' + id,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                data: data,
                onUploadProgress: (progressEvent) => {
                    const progressPercentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(progressPercentage);
                }
            };

            axios(config)
                .then(function (response) {
                    setLoader(false)
                    setSubmitting(false);
                    if (response.data.status) {
                        getObjectPhotos()
                        toast.success('Photos/ Videos has been successfully saved.')
                        setSelectedImage([])
                        setObjectVideos([])
                        setObjectPhotos([])
                        let files_arr = []
                        let resp_arr = response.data.data;
                        Array.from(resp_arr).forEach(data => {
                            files_arr.push(data.file.path);
                        });
                        // setCompletedCount(selectedFiles.length);
                        setUploading(false);
                        setProgress(0);
                        // setObjectPhotos([...objectPhotos, ...files_arr]);
                    }
                    else {
                        toast.error('Some error occured from backend response.')

                        // setError('Some error occured.')
                    }
                    //handleChangeClick();
                })
                .catch(function (error) {
                    setLoader(false)
                    console.log(error)
                    toast.error('Some internal error occured.')
                    setSubmitting(false);
                    setUploading(false);
                    setProgress(0);

                });

        } else {
            setLoader(false)
            setError('Please select at least one photo/ video to upload.')
            return false;
        }
    }

    function imageDisplay(e) {
        // var imageData1 = Object.values(e.target.files);
        if (e.target.files && e.target.files.length > 0) {
            setError(false)
            setRespMsg("")
            setImgLoading(true)
            setUploading(true)
            var files = e.target.files;
            let image_Arr = [];
            const allowedFileTypes = ["jpg", "jpeg", "gif", "png", "heic", "mp4", "mpeg", "ogg", "webm"]
            Array.from(files).forEach(file => {
                var fileNameExt = file.name.substr(file.name.lastIndexOf('.') + 1);
                if (!allowedFileTypes.includes(fileNameExt)) {
                    setError(`File does not support. Files type must be ${allowedFileTypes.join("/ ")}`);
                    setImgLoading(false)
                    return false;
                }
                else {
                    if (fileNameExt === "heic") {
                        var blob = file;
                        heic2any({
                            blob: blob,
                            toType: "image/jpg",
                        })
                            .then(function (resultBlob) {
                                image_Arr.push(resultBlob);
                            })
                            .catch(function (x) {
                                console.log(x.code);
                                console.log(x.message);
                            });
                    }
                    else {
                        image_Arr.push(file);
                    }
                }
            });
            setTimeout(() => {
                setImgLoading(false);
                setUploading(false);
                setSelectedImage([...selectedImage, ...image_Arr]);
            }, 3000);
        }

    }



    // This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = (e) => {
        var array = [...selectedImage]; // make a separate copy of the array
        var index = e.target.name;
        if (index !== -1) {
            array.splice(index, 1);
            setSelectedImage(array);
        }
    };


    // This function will be triggered for remove selected pics
    const removeFiles = (e) => {
        let payload = {
            files: e === 'img' ? selectedImgs : selectedVideos
        };
        // var config = {
        //     method: 'post',
        //     url: apiUrl + 'listing/delete-object-photo/' + id,
        //     headers: {
        //         'Accept': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
        //     },
        //     data: payload
        // };
        console.log(payload)
        setLoader(true)
        // axios(config)
        requestDeleteObjectPic(id, payload)
            .then(function (response) {
                setLoader(false)
                getObjectPhotos()
                setSelectedImage([])
                setSelectedFiles([])
                setSelectedImgs([])
                setSelectedVideos([])

            })
            .catch(function (error) {
                setLoader(false)
                console.log(error);
            });
    };

    const getObjectPhotos = () => { // "we are using this function for getting the images of particular listing type"
        // var config = {
        //     method: 'get',
        //     url: apiUrl + 'listing/get-object-photos/' + id,
        //     headers: {
        //         'Accept': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
        //     },
        // };
        // axios(config)
        getRequestObjectPhotos(id)
            .then(function (response) {
                setObjectPhotos(response.data.data.photos.filter(function (item) {
                    return item.file.path.split('.').pop() !== 'mp4';
                }))

                setObjectVideos(response.data.data.photos.filter(function (item) {
                    return item.file.path.split('.').pop() === 'mp4';
                }))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => { // "here we are using the function in useffect to trigger the function" 
        console.log('objectid', id)
        getObjectPhotos()
        // eslint-disable-next-line
    }, [id]);


    const handleCheckFile = (type, item) => { // "function for check the selected images"

        if (item.file.type === "video/mp4") {
            if (type) {
                setSelectedVideos(e => [...e, item.id]); // "make a seprate copy"
            } else {
                setSelectedVideos([...selectedFiles.filter((e) => e !== item.id)])
            }
        } else {
            if (type) {
                setSelectedImgs(e => [...e, item.id]); // "make a seprate copy"
            } else {
                setSelectedImgs([...selectedFiles.filter((e) => e !== item.id)])
            }
        }
    };





    return (
        <>
            <AfterLoginHeader userData={userData} />
            <section className="section" style={{ backgroundColor: '#F9F9F9' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="tell_us_about_yourself_title">PLEASE UPLOAD THE IMAGES & VIDEO</h3>
                        </div>

                    </div>
                    <div className="upload_images_section">
                        {respMsg && <div className="alert alert-success mt-5">{respMsg}</div>}
                        {error && <div className="alert alert-danger mt-3">{error}</div>}
                        <div className="upload__box">
                            <div className="upload__btn-box">
                                <img src={UPLOADICONIMAGE} alt="upload" />

                                <p className="click_here_to_upload_images_text">Click Here To Upload Images And Video</p>

                                <label className="upload__btn">

                                    <p>Select Images/Videos</p>
                                    <input type="file" id="image_input" className="upload__inputfile" accept={allowExtensions} onChange={imageDisplay} multiple />
                                </label>
                            </div>
                            <div className="upload__img-wrap">
                                {imgloading && (<div className="img-loader text-center"><FontAwesomeIcon icon={faSpinner} /> <h2>Loading..</h2> </div>)}
                                {!imgloading && selectedImage && (
                                    selectedImage.map((imageSep, index) => (
                                        <div className='col-md-4' >
                                            <div style={styles.displayImage}>
                                                {imageSep.type === "video/mp4" ? <FontAwesomeIcon icon={faVideo} className="video_icon" /> :
                                                    <LazyLoadImage src={URL.createObjectURL(imageSep)} alt='thumb' effect='blur' style={styles.image} />

                                                }
                                                <button onClick={removeSelectedImage} name={index} style={styles.delete}>
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>

                        </div>
                    </div>
                    {uploading && (
                        <div>
                            <Progress bar value={progressValue} max={100}>
                                {progressValue}%
                            </Progress>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                {
                                    loader ?
                                        <div className="loader"></div> : <button className="save_btn" disabled={submitting ? true : false} onClick={savePhotos}>Upload</button>
                                }
                            </center>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="row">
                        <div className='col-md-12'>
                            <h5>Uploaded Photos</h5>
                        </div>
                        {
                            objectPhotos && (
                                objectPhotos.map((data, index) => {
                                    let check;
                                    for (let i = 0; i < selectedImgs.length; i++) {
                                        if (selectedImgs[i] === data.id) {
                                            check = true;
                                            break;
                                        } else {
                                            check = false
                                        }
                                    }

                                    return (
                                        <div className='col-md-3 col-sm-6' >
                                            <div style={styles.displayImage}>
                                                <LazyLoadImage src={data.file.path} style={styles.image} alt='Loading...' effect='blur' zoom={true} />

                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={data.id}
                                                    checked={check}
                                                    id="flexCheckDefault"
                                                    onChange={e => handleCheckFile(e.target.checked, data)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            )
                        }
                    </div>
                </div>
                {(selectedImgs.length > 0) &&
                    <div className='col-md-12 delete-content'>


                        {
                            loader ?
                                <div className="loader"></div> :
                                <button onClick={() => removeFiles('img')}>
                                    Delete
                                </button>}

                    </div>
                }
                <div className='col-md-12'>
                    <div className="row">
                        <div className='col-md-12'>
                            <h5>Uploaded Videos</h5>
                        </div>
                        {objectVideos && (
                            objectVideos.map((data, index) => {
                                let check;
                                for (let i = 0; i < selectedVideos.length; i++) {
                                    if (selectedVideos[i] === data.id) {
                                        check = true;
                                        break;
                                    } else {
                                        check = false
                                    }
                                }
                                return (
                                    <div className='col-md-3 col-sm-6' >
                                        <div style={styles.displayImage}>
                                            <video style={{ width: '100%' }}>
                                                <source src={data.file.path} type="video/mp4" />
                                            </video>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={check}
                                                value={data.id}
                                                id="flexCheckDefault"
                                                onChange={e => handleCheckFile(e.target.checked, data)}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        )}

                    </div>
                    {(selectedVideos.length > 0) &&
                        <div className='col-md-12 delete-content'>

                            {
                                loader ?
                                    <div className="loader"></div> :
                                    <button onClick={() => removeFiles('video')}>
                                        Delete
                                    </button>
                            }
                        </div>
                    }


                </div>
                <Footer />
            </section >
        </>
    );
}

export default EditRequestObjectPhotos