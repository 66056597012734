import React, { useState, useEffect } from "react";
import SellerDetail from './SellerDetail';
import { Controller } from "react-hook-form";
import SearchSelect from "../../selectSearch/SearchSelect";

const Step4 = ({ handleFormData, values, register, handleAddress, error, selectValues, clearErrors, setValue, control, getValues }) => {

    const [filteredLicenseArea, setFilteredLicenseArea] = useState([]);// state for store the data of filtered licenses area


    useEffect(() => {
        changeSpecies(getValues('LicenseSpecies'))
    },
        // eslint-disable-next-line
        [getValues('LicenseArea')]
    );


    //handle for set the species of license area and subspecies fields on onChange of input values 
    const changeSpecies = (e) => {
        // let val = e.target.value;
        setFilteredLicenseArea(Array.from(selectValues.brokerArea.filter(function (x) {
            return x.generalspecies2[0]?.general_species_id === e
        })));
        // getSubSpecies(val);
    }


    return (
        <>
            {(values.ItemType === 'Boat')
                ? <SellerDetail handleFormData={handleFormData} values={values} register={register} clearErrors={clearErrors} handleAddress={handleAddress} error={error} /> :
                <>
                    <p className="good_day_para">License details</p>
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <span className="popup_input_label">Name of boat on which license is finished<span className="required-field"></span></span>
                            </div>
                            <div className="col-md-12">
                                <input type="text" name="boatName" defaultValue={values.boatName} className="popup_input" id="boatName" {...register("boatName", { required: "This field is required" })}
                                    // onChange={handleFormData} 
                                    onChange={(e) => {
                                        clearErrors("boatName");
                                        handleFormData(e);
                                    }}
                                />
                                {error.boatName && <span className="err-msg">{error.boatName.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <span className="popup_input_label">Species<span className="required-field"></span></span>
                            </div>
                            <div className="col-md-12">
                                <Controller
                                    name="LicenseSpecies"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'This field is required' }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => < SearchSelect
                                        onChange={(selectedOption) => {
                                            setValue('LicenseSpecies', selectedOption?.value);
                                            changeSpecies(selectedOption.value);
                                            handleFormData({
                                                target: {
                                                    value: selectedOption?.value,
                                                    name: "LicenseSpecies"
                                                }
                                            })
                                            clearErrors('LicenseSpecies')
                                        }}
                                        defaultValue={
                                            selectValues.generalSpecies.find(
                                                (e) => e.id === getValues("LicenseSpecies")
                                            )
                                                ? {
                                                    value: selectValues.generalSpecies.find(
                                                        (e) => e.id === getValues("LicenseSpecies")
                                                    ).id,
                                                    label: selectValues.generalSpecies.find(
                                                        (e) => e.id === getValues("LicenseSpecies")
                                                    )?.name,
                                                }
                                                : ""
                                        }
                                        options={selectValues.generalSpecies?.map((berth, index) => { return { value: berth.id, label: berth.name }; })}
                                    />

                                    }
                                />
                                {error.LicenseSpecies && <span className="err-msg">{error.LicenseSpecies.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <span className="popup_input_label">License Area<span className="required-field"></span></span>
                            </div>
                            <div className="col-md-12">
                                <Controller
                                    name="LicenseArea"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'This field is required' }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => < SearchSelect
                                        onChange={(selectedOption) => {
                                            setValue('LicenseArea', selectedOption?.value);
                                            // handleArea(selectedOption);
                                            handleFormData({
                                                target: {
                                                    value: selectedOption.value,
                                                    name: "LicenseArea"
                                                }
                                            })
                                            clearErrors('LicenseArea')
                                        }}
                                        defaultValue={
                                            filteredLicenseArea.find(
                                                (e) => e.id === getValues("LicenseArea")
                                            )
                                                ? {
                                                    value: filteredLicenseArea.find(
                                                        (e) => e.id === getValues("LicenseArea")
                                                    ).id,
                                                    label: filteredLicenseArea.find(
                                                        (e) => e.id === getValues("LicenseArea")
                                                    )?.name,
                                                }
                                                : ""
                                        }
                                        options={filteredLicenseArea?.map((region, index) => { return { value: region.id, label: region.name }; })}
                                    />

                                    }
                                />
                                {error.LicenseArea && <span className="err-msg">{error.LicenseArea.message}</span>}

                            </div>
                        </div>
                    </form>
                </>
            }
        </>
    );
}

export default Step4;