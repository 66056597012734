import { useState, useEffect } from 'react';
import { Done } from "../../constants/imagepath";

const BecomeReferralAgentRequestCompleted = (props) => {


    useEffect(() => {

    }, []);


    return (
        <>
            <div>
                <center><img src={Done} className="submitted_icon" alt='Loading...' /></center>
                <p className="your_data_has_been_text">Your Request To Become Referral Agent Has Been Submitted <span>Successfully.</span></p>

            </div>

        </>
    );
}

export default BecomeReferralAgentRequestCompleted