/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./listing.css";
import { LOGO, CLOSEICON } from "../../constants/imagepath";
import { useForm } from "react-hook-form";
import heic2any from "heic2any";
import { buyButtonSubmit } from "../../api_services/api_calls/buyButtonApi";
import {
  buyerGetEquipmentOptions,
  buyerGetSeaFoodOptions,
  buyerLicenseObject,
  buyerObjectFiltersNew,
} from "../../api_services/api_calls/buyerListingObjectApi";
import { LazyLoadImage } from "react-lazy-load-image-component";

import BuyerListingCompleted from "./BuyerListingCompleted";
import Step1 from "./BuyerListingSteps/Step1";
import Step2 from "./BuyerListingSteps/Step2";
import Step3 from "./BuyerListingSteps/Step3";
import Step4 from "./BuyerListingSteps/Step4";
import Step5 from "./BuyerListingSteps/Step5";
import Step6 from "./BuyerListingSteps/Step6";
import Step7 from "./BuyerListingSteps/Step7";
import Step8 from "./BuyerListingSteps/Step8";
import Step9 from "./BuyerListingSteps/Step9";
import Step10 from "./BuyerListingSteps/Step10";
import Step11 from "./BuyerListingSteps/Step11";
import Step12 from "./BuyerListingSteps/Step12";
import StepImageVideo from "./BuyerListingSteps/StepImageVideo";
import BuyIntroVideoModal from "../Modals/BuyIntroVideoModal";
const BuyerListing = (props) => {
  const {
    register,
    trigger,
    formState: { errors },
    getValues,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm(); // retrieve all hook methods
  const [step, setStep] = useState(0); // state for store the steps data
  const [userName, setUserName] = useState(""); // state for store the username data
  const [stepActive, setActiveStep] = useState(2);
  const [submitting, setSubmitting] = useState(false); // state for store the submitting value
  const [listingCompleted, setListingCompleted] = useState(false); // state for store the listing data
  const [btnError, setBtnError] = useState(""); // state for store the errors
  // const [flag, setFlag] = useState(true); // state for store the value of flag
  const [loader, setLoader] = useState(false);
  const [isIntroVideo, setIsIntroVideo] = useState(true);
  // state to store all the parameters of buyer which is required for buy a boat
  const [selectedImage, setSelectedImage] = useState([]);
  const [imgloading, setImgLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [state, setState] = useState({
    BuyType: "Buy",
    ItemType: "Boat",
    VesselType: "Fishing",

    // ---------- changes i did
    MinLengthFoot: "",
    MinLengthInch: "",
    MaxLengthFoot: "",
    MaxLengthInch: "",
    LengthUnit: "FT-IN",

    // --------

    MinWidthFoot: "",
    MinWidthInch: "",
    MaxWidthFoot: "",
    MaxWidthInch: "",
    WidthUnit: "FT-IN",

    MinGrossTonnage: "",
    MaxGrossTonnage: "",
    ConstructionMetarial: "",
    MinYearBuilt: "",
    MaxYearBuilt: "",
    MinPriceWillingToPay: "",
    MaxPriceWillingToPay: "",
    Currency: "",
    FinanceInplace: "Yes",
    Description: "",
    BuyPlan: "3 months",
    LicenseNumber: "",
    LicenseSpecies: "",
    LicenseRegion: "",
    LicenseArea: [],
    LicenseProvince: "",
    LicenseType: "",
    LicensePrice: "",
    LicenseSaleType: 9,
    QuotaSaleType: 8,
    PleasureBoatName: "",
    PleasureClass: "",
    PleasureCategory: "",
    PleasureBoatPrice: "",
    EquipmentCategory: "",
    EquipmentProduct: "",
    EquipmentPrice: "",
    EquipmentCurrency: "",
    EquipmentManufacturer: "",
    EquipmentProvince: "",
    SeaFoodRegion: "",
    SeaFoodSpecies: "",
    SeaFoodSize: "",
    SeaFoodMinQuantity: "",
    SeaFoodMaxQuantity: "",
    SeaFoodProvince: "",
    SeaFoodCountry: "",
    SeaFoodPrice: "",
    SeaFoodCurrency: "",
    SeaFoodDescription: "",
  });

  // state for store the  species, states, region,areas of the required items
  const [listingOptions, setListingOptions] = useState({
    constructionTypes: [],
    generalSpecies: [],
    brokerArea: [],
    brokerLicenseTypeGeneral: [],
    constructions: [],
    states: [],
    brokerEquipmentCategory: [],
    brokerEquipmentProduct: [],
    brokerEquipmentManufacturer: [],
    brokerGeneralSpecies: [],
    brokerRegion: [],
    brokerGeneralSpeciesSize: [],
  });


  console.log('step',step)
  function imageDisplay(e) {
    // var imageData1 = Object.values(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      setErrorMsg(false);
      // setRespMsg("")
      setImgLoading(true);
      // setUploading(true)
      var files = e.target.files;
      let image_Arr = [];
      const allowedFileTypes = [
        "jpg",
        "jpeg",
        "gif",
        "png",
        "heic",
        "mp4",
        "mpeg",
        "ogg",
        "webm",
      ];
      Array.from(files).forEach((file) => {
        var fileNameExt = file.name.substr(file.name.lastIndexOf(".") + 1);
        if (!allowedFileTypes.includes(fileNameExt)) {
          setErrorMsg(
            `File does not support. Files type must be ${allowedFileTypes.join(
              "/ "
            )}`
          );
          setImgLoading(false);
          return false;
        } else {
          if (fileNameExt == "heic") {
            var blob = file;
            heic2any({
              blob: blob,
              toType: "image/jpg",
            })
              .then(function (resultBlob) {
                image_Arr.push(resultBlob);
              })
              .catch(function (x) {
                console.log(x.code);
                console.log(x.message);
              });
          } else {
            image_Arr.push(file);
          }
        }
      });
      setTimeout(() => {
        setImgLoading(false);
        // setUploading(false);
        setSelectedImage([...selectedImage, ...image_Arr]);
      }, 3000);
    }
  }

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = (e) => {
    var array = [...selectedImage]; // make a separate copy of the array
    var index = e.target.name;
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedImage(array);
    }
  };
  //useEffect function for get the states , areas for all the listing
  useEffect(() => {
    if (props.profileStatus) {
      setUserName(props.userData.profile.profile_data.first_name);
    } else {
      setUserName(props.userData.user.email);
    }
    buyerObjectFiltersNew() //Buyer object flters api call for get constructions and states
      .then(function (response) {
        setListingOptions((listingOptions) => ({
          ...listingOptions,
          constructions: response.data.data.constructions,
          states: response.data.data.states,
          // ["states"]: response.data.data.states,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });
    buyerLicenseObject() //Buyer license object api call for get generalSpecies,brokerRegion and brokerLicenseTypeGeneral
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            generalSpecies: response1.data.data.generalSpecies,
            brokerRegion: response1.data.data.region,
            brokerLicenseTypeGeneral:
              response1.data.data.brokerLicenseTypeGeneral,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    buyerGetEquipmentOptions() //Buyer equipment  api call for get brokerEquipmentCategory,brokerEquipmentProduct and brokerEquipmentManufacturer
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            brokerEquipmentCategory: response1.data.data.brokerGearCategory,
            brokerEquipmentProduct: response1.data.data.brokerGearProduct,
            brokerEquipmentManufacturer:
              response1.data.data.brokerGearManufacturer,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    buyerGetSeaFoodOptions() //Buyer get seefoof object api call for get brokerGeneralSpecies,and brokerGeneralSpeciesSize
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            brokerGeneralSpecies: response1.data.data.brokerGeneralSpecies,
            brokerGeneralSpeciesSize:
              response1.data.data.brokerGeneralSpeciesSize,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  // handle for set all the validations according to all steps
  function triggerValidation(action) {
    console.log('steps', step)
    const fields_arr = [
      {},
      {},
      {
        fields: {
          License: [
            "LicenseSpecies",
            "LicenseRegion",
            "LicenseArea",
            "LicenseProvince",
            "MinPriceWillingToPay",
            "MaxPriceWillingToPay",
          ],
          Quota: [
            "LicenseSpecies",
            "LicenseRegion",
            "LicenseArea",
            "LicenseProvince",
            "MinPriceWillingToPay",
            "MaxPriceWillingToPay",
          ],
          Equipment: [
            "EquipmentManufacturer",
            "EquipmentCategory",
            "EquipmentProduct",
            "EquipmentProvince",
            "MinPriceWillingToPay",
            "MaxPriceWillingToPay",
          ],
          Seafood: [
            "SeaFoodRegion",
            "SeaFoodSpecies",
            "SeaFoodSize",
            "SeaFoodMinQuantity",
            "SeaFoodMaxQuantity",
            "SeaFoodProvince",
            "MinPriceWillingToPay",
            "MaxPriceWillingToPay",
            "SeaFoodProvince",
            "SeaFoodCountry",
          ],
        },
      },
      {
        fields: {
          Boat: [
            "MinLengthFoot",
            "MinLengthInch",
            "MaxLengthFoot",
            "MaxLengthInch",
            "MinWidthFoot",
            "MinWidthInch",
            "MaxWidthFoot",
            "MaxWidthInch",
          ],
        },
      },
      {
        fields: { Boat: ["MinGrossTonnage", "MaxGrossTonnage"] },
      },
      {
        fields: { Boat: ["ConstructionMetarial"] },
      },
      {
        fields: { Boat: ["MinYearBuilt", "MaxYearBuilt"] },
      },
      {
        fields: { Boat: ["MinPriceWillingToPay", "MaxPriceWillingToPay"] },
      },
      {},
      {},
      {
        fields: { Boat: ["BuyPlan"] },
      },
      {
        fields: ["AgreementType"],
      },
    ];
    if (step === 0) {
      if (state.BuyType === "") {
        setBtnError("Please select one of above");
        return false;
      } else {
        setBtnError("");
        setStep(step + 1);
        setActiveStep(stepActive + 1);
      }
    }
    if (step === 1) {
      if (state.ItemType === "") {
        setBtnError("Please select listing type");
        return false;
      } else {
        setBtnError("");
        setStep(step + 1);
        setActiveStep(stepActive + 1);
      }
    }
    if (step === 2) {
      if (state.ItemType === "Boat") {
        if (state.VesselType === "") {
          setBtnError("Please select required fields");
          return false;
        } else {
          setBtnError("");
          setStep(step + 1);
          setActiveStep(stepActive + 1);
        }
      }
      if (state.ItemType === "License") {
        trigger(fields_arr[step].fields["License"]).then((valid) => {
          if (valid) {
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              // setFlag(false);
            }
          }
        });
      }
      if (state.ItemType === "Quota") {
        trigger(fields_arr[step].fields["Quota"]).then((valid) => {
          if (valid) {
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              // setFlag(false);
            }
          }
        });
      }
      if (state.ItemType === "Equipment") {
        trigger(fields_arr[step].fields["Equipment"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              // setFlag(false);
            }
        });
      }
      if (state.ItemType === "Seafood") {
        trigger(fields_arr[step].fields["Seafood"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
              setActiveStep(stepActive + 1);
            } else {
              submitListing();
              // setFlag(false);
            }
        });
      }
    }

    if (step === 3) {
      trigger(fields_arr[step].fields["Boat"]).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }

    if (step === 4) {
      trigger(fields_arr[step].fields["Boat"]).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }

    if (step === 5) {
      trigger(fields_arr[step].fields["Boat"]).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }

    if (step === 6) {
      trigger(fields_arr[step].fields["Boat"]).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }

    if (step === 7) {
      trigger(fields_arr[step].fields["Boat"]).then((valid) => {
        if (valid) setStep(step + 1);
        setActiveStep(stepActive + 1);
      });
    }

    if (step === 8) {
      setStep(step + 1);
      setActiveStep(stepActive + 1);
    }

    if (step === 9) {
      setStep(step + 1);
      setActiveStep(stepActive + 1);
    }
    if (step === 10) {
      setStep(step + 1);
      setActiveStep(stepActive + 1);
    }
    if (step === 11) {
      setStep(step + 1);
      setActiveStep(stepActive + 1);
    }

    if (step === 12 && action === "final") {
      if (state.BuyPlan === "") {
        setBtnError("Please select one of above");
        return false;
      } else {
        setBtnError("");
        submitListing();
        // setFlag(false);
      }
    }
  }

  const handleIntroVideoModal = (res) => setIsIntroVideo(res);
  //handle to identify the step number and for next step
  function setNextStep(e) {
    e.preventDefault();
    triggerValidation("next");
  }

  //handle to identify the step number and for previous step
  function setPrevStep(e) {
    setBtnError("");
    setStep(step - 1);
    e.preventDefault();
    clearErrors();
  }

  // handle for change props it will triggered the value of props.onChange
  function handleChangeClick(event) {
    props.onChange(false);
  }

  //handle to set the value of active button according to user
  function handleButtonSelect(evt) {
    evt.className += "active";
    const value = evt.target.value;
    console.log('value', value);
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setStep(step + 1);
    setBtnError("");
    reset();
  }

  //handle to set the value of active button according to user
  function handleButtonInput(evt) {
    evt.className += "active";
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    if (evt.target.name == "UploadLater") {
      setStep(step + 1);
      setActiveStep(stepActive + 1);
    }
  }

  // handle for set the licences broker Areas
  function handleLicenseArea(data) {
    setListingOptions((listingOptions) => ({
      ...listingOptions,
      brokerArea: data,
    }));
  }

  // handle for set the value on onChange of data in input field
  function handleChange(data) {
    const value = data.target.value;
    let selectedOption = data.target.value;
    if (data.target.name === "LicenseArea") {
      const selected = [];
      selected.push(selectedOption);
      setState({
        ...state,
        [data.target.name]: selected,
      });
    } else {
      setState({
        ...state,
        [data.target.name]: value,
      });
    }
    // focusOnElement(evt.target.id);
  }

  // handle for submit the final result on last step it will check all the steps are filled after that its proceed(Buyer Listing)
  function submitBuyerListing(e) {
    e.preventDefault();
    triggerValidation("final");
  }

  // handle for append the data of input fields in the API
  function submitListing() {
    setSubmitting(true);
    var data = new FormData();
    if (state.BuyType === "Sell") {
      data.append("buy_lease", "1");
    } else {
      data.append("buy_lease", "2");
    }
    data.append("request_type", state.ItemType);
    data.append("min_price", state.MinPriceWillingToPay);
    data.append("max_price", state.MaxPriceWillingToPay);
    data.append("is_active", "potential");
    data.append("status", "Potential");
    data.append("upload_later", state.UploadLater);
    for (let i = 0; i < selectedImage.length; i++) {
      data.append("files[]", selectedImage[i]);
    }
    if (state.ItemType === "Boat") {
      data.append("package_type", "6");
      data.append("item", state.ItemType);
      data.append("vessel_type", state.VesselType);
      data.append("min_length_foot", state.MinLengthFoot);
      data.append("min_length_inch", state.MinLengthInch);
      data.append("max_length_foot", state.MaxLengthFoot);
      data.append("max_length_inch", state.MaxLengthInch);
      data.append("length_unit", state.LengthUnit);
      data.append("min_width_foot", state.MinWidthFoot);
      data.append("min_width_inch", state.MinWidthInch);
      data.append("max_width_foot", state.MaxWidthFoot);
      data.append("max_width_inch", state.MaxWidthInch);
      data.append("width_unit", state.WidthUnit);
      data.append("min_gross_tonnage", state.MinGrossTonnage);
      data.append("max_gross_tonnage", state.MaxGrossTonnage);
      data.append("construction_material", state.ConstructionMetarial);
      data.append("min_year_built", state.MinYearBuilt);
      data.append("max_year_built", state.MaxYearBuilt);
      data.append("currency", state.Currency);
      data.append("finance_inplace", state.FinanceInplace);
      data.append("description", state.Description);
      data.append("buy_plan", state.BuyPlan);
      data.append("currency", "USD");
    } else if (state.ItemType === "License") {
      data.append("package_type", "21");
      data.append("license_number", state.LicenseNumber); // License Number
      data.append("license_currency", "USD");
      data.append("general_species", state.LicenseSpecies);
      data.append("region", state.LicenseRegion);
      for (let i = 0; i < state.LicenseArea.length; i++) {
        data.append("areas[]", state.LicenseArea[i]);
      }
      data.append("province", state.LicenseProvince);
      data.append("license_type", state.LicenseType);
      data.append("sale_type_details", state.LicenseSaleType);
    } else if (state.ItemType === "Quota") {
      data.append("package_type", "21");
      data.append("license_currency", "USD");
      data.append("general_species", state.LicenseSpecies);
      data.append("region", state.LicenseRegion);
      for (let i = 0; i < state.LicenseArea.length; i++) {
        data.append("areas[]", state.LicenseArea[i]);
      }
      data.append("province", state.LicenseProvince);
      data.append("sale_type_details", state.QuotaSaleType);
    } else if (state.ItemType === "Equipment") {
      data.append("package_type", "10");
      data.append("gear_currency", "USD");
      data.append("category", state.EquipmentCategory);
      data.append("product", state.EquipmentProduct);
      data.append("manufacturer", state.EquipmentManufacturer);
      data.append("province", state.EquipmentProvince);
    } else if (state.ItemType === "Seafood") {
      data.append("package_type", "30");
      data.append("seafood_currency", "USD");
      data.append("seafood_region", state.SeaFoodRegion);
      data.append("seafood_general_species", state.SeaFoodSpecies);
      data.append("seafood_size", state.SeaFoodSize);
      data.append("seafood_min_quantity", state.SeaFoodMinQuantity);
      data.append("seafood_max_quantity", state.SeaFoodMaxQuantity);
      data.append("seafood_province", state.SeaFoodProvince);
      data.append("seafood_country", state.SeaFoodCountry);
    }
    setLoader(true);
    buyButtonSubmit(data) //Api for set the final result of buyer listing ()
      .then(function (response) {
        setLoader(false);
        // setFlag(true);
        // setLoader(false);
        if (response.data.status) {
          setListingCompleted(true);
        } else {
          alert("Some error occured.");
          setSubmitting(false);
        }
        // handleChangeClick();
      })
      .catch(function (error) {
        console.log(error);
        // setFlag(true);
        setSubmitting(false);
      });
  }

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  console.log("listingOptions", listingOptions);
  return (
    <>
      <div id="popup1" className="overlay_2">
        <div className="popup1">
          <div className="popup1-header">
            <LazyLoadImage
              src={LOGO}
              alt="Logo"
              effect="blur"
              className="logoImg"
            />
          </div>
          <a
            className="close"
            onClick={handleChangeClick}
            href="javascript:void(0)"
          >
            <img src={CLOSEICON} alt="closebutton" />
          </a>
          <section className="multi_step_form">
            <form id="msform">
              {(() => {
                return (
                  <ul id="buy_progressbar">
                    {numbers.map((number) => (
                      <li className={stepActive >= number ? "active" : ""}></li>
                    ))}
                  </ul>
                );
              })()}
              <fieldset>
                <div className="conten" style={{ minHeight: 272 }}>
                  {listingCompleted ? (
                    <BuyerListingCompleted
                      stateData={state}
                      listingOptions={listingOptions}
                    />
                  ) : isIntroVideo ? 
                    <BuyIntroVideoModal 
                    userName={userName}
                    userData={props.userData.profile.profile_data}
                    isIntroVideo={isIntroVideo}
                    setIntroModalOpen={handleIntroVideoModal}
                    />
                  : step === 0 ? (
                    <Step1
                      userName={userName}
                      values={state}
                      handleFormData={handleButtonSelect}
                      trigger={trigger}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      error={btnError}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 1 ? (
                    <Step2
                      values={state}
                      handleFormData={handleButtonSelect}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      getValues={getValues}
                      error={btnError}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 2 ? (
                    <Step3
                      values={state}
                      handleFormData={handleChange}
                      handleButtonInput={handleButtonInput}
                      trigger={trigger}
                      getValues={getValues}
                      btnError={btnError}
                      error={errors}
                      clearErrors={clearErrors}
                      control={control}
                      setValue={setValue}
                      register={register}
                      selectValues={listingOptions}
                      handleLicenseArea={handleLicenseArea}
                    />
                  ) : step === 3 ? (
                    <Step4
                      values={state}
                      handleFormData={handleChange}
                      register={register}
                      error={errors}
                      clearErrors={clearErrors}
                      getValues={getValues}
                      trigger={trigger}
                      control={control}
                      setValue={setValue}
                      selectValues={listingOptions}
                    />
                  ) : step === 4 ? (
                    <Step5
                      values={state}
                      handleFormData={handleChange}
                      register={register}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      getValues={getValues}
                      control={control}
                      setValue={setValue}
                      error={errors}
                    />
                  ) : step === 5 ? (
                    <Step6
                      values={state}
                      handleFormData={handleChange}
                      register={register}
                      clearErrors={clearErrors}
                      getValues={getValues}
                      error={errors}
                      selectValues={listingOptions}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 6 ? (
                    <Step7
                      values={state}
                      handleFormData={handleChange}
                      register={register}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      getValues={getValues}
                      error={errors}
                      selectValues={listingOptions}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 7 ? (
                    <Step8
                      values={state}
                      handleFormData={handleChange}
                      register={register}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      getValues={getValues}
                      error={errors}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 8 ? (
                    <Step9
                      values={state}
                      handleFormData={handleChange}
                      register={register}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      getValues={getValues}
                      error={errors}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 9 ? (
                    <Step10
                      values={state}
                      handleFormData={handleButtonInput}
                      register={register}
                      clearErrors={clearErrors}
                      getValues={getValues}
                      trigger={trigger}
                      error={errors}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 10 ? (
                    <Step11
                      values={state}
                      handleFormData={handleChange}
                      register={register}
                      clearErrors={clearErrors}
                      getValues={getValues}
                      trigger={trigger}
                      error={errors}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 11 ? (
                    <Step12
                      values={state}
                      handleFormData={handleButtonInput}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      getValues={getValues}
                      error={btnError}
                      control={control}
                      setValue={setValue}
                    />
                  ) : step === 12 ? (
                    <StepImageVideo
                      values={state}
                      handleImageDisplay={imageDisplay}
                      errorMsg={errorMsg}
                      handleChangeNormal=""
                      selectedImage={selectedImage}
                      styles={styles}
                      removeSelectedImage={removeSelectedImage}
                      clearErrors={clearErrors}
                      imgloader={imgloading}
                    />
                  ) : ( "")}
                </div>
                {listingCompleted ? (
                  <center>
                    <Link to="/your-requests">
                      <button
                        type="button"
                        className="action-button float-none"
                      >
                        Go To Buyer Request{" "}
                      </button>
                    </Link>
                  </center>
                ) : (
                  <>
                    {step > 0 ? (
                      <button
                        className="action-button previous_button"
                        onClick={setPrevStep}
                      >
                        Back
                      </button>
                    ) : (
                      ""
                    )}

                    {!isIntroVideo ?
                   ( step < 12 && state.ItemType === "Boat"  ? (
                      <button
                        className="next action-button"
                        disabled={submitting ? true : false}
                        onClick={setNextStep}
                      >
                        Next
                      </button>
                    ) : (
                      ""
                    )) : ( "" )}

                    {loader ? (
                      <div className="loader"></div>
                    ) : state.ItemType === "Boat" && step === 12 ? (
                      <button
                        className="next action-button buy_finish_btn"
                        disabled={submitting ? true : false}
                        onClick={submitBuyerListing}
                      >
                        Finish
                      </button>
                    ) : (state.ItemType === "License" || state.ItemType === "Quota" ||
                      state.ItemType === "Pleasure Boat" ||
                      state.ItemType === "Seafood" ||
                      state.ItemType === "Equipment" ||
                      state.ItemType === "Package") &&
                      step === 2 ? (
                      <button
                        className="next action-button buy_finish_btn"
                        disabled={submitting ? true : false}
                        onClick={submitBuyerListing}
                      >
                        Finish
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </fieldset>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default BuyerListing;
// Just some styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320 },
  delete: {
    cursor: "pointer",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "27px",
    position: "absolute",
    right: "18px",
  },
  displayImage: {
    border: "1px solid grey",
    margin: "5px 2px 2px 2px",
  },
  imgcontainer: {
    borderTop: "2px solid grey",
  },
};
