import { useState, useEffect } from 'react';
import { Done } from "../../constants/imagepath";

const SellerListingCompleted = (props) => {
	const [berthName, setBerthName] = useState('');
	const [constructionMet, setConstructionMet] = useState('');
	const [engineType, setEngineType] = useState('');
	const [engineBrand, setEngineBrand] = useState('');
	const [reductionType, setReductionType] = useState('');
	const [licenseSpecies, setLicenseSpecies] = useState('');
	const [licenseArea, setLicenseArea] = useState('');
	const [licenseProvince, setLicenseProvince] = useState('');
	const [licenseType, setLicenseType] = useState('');
	const [saleType, setSaleType] = useState('');
	const [pleasureboatClass, setPleasureboatClass] = useState('');
	const [pleasureboatCategory, setPleasureboatCategory] = useState('');
	const [equipmentCategory, setEquipmentCategory] = useState('');
	const [equipmentProductType, setEquipmentProductType] = useState('');

	const [seafoodSpecies, setSeafoodSpecies] = useState('');
	const [seafoodSize, setSeafoodSize] = useState('');

	useEffect(() => {
		setBerthName(getNameById(props.listingOptions.berths, props.stateData.VesselLocation));
		setConstructionMet(getNameById(props.listingOptions.constructions, props.stateData.ConstructionMet));
		setEngineType(getNameById(props.listingOptions.enginetypes, props.stateData.TypeOfEngine));
		setEngineBrand(getNameById(props.listingOptions.enginemanufacturers, props.stateData.EngineBrandName));
		setReductionType(getNameById(props.listingOptions.marineGearManufacturer, props.stateData.TypeOfReduction));
		setLicenseSpecies(getNameById(props.listingOptions.generalSpecies, props.stateOtherData.LicenseSpecies));
		setLicenseArea(getNameById(props.listingOptions.brokerArea, props.stateOtherData.LicenseArea));
		setLicenseProvince(getNameById(props.listingOptions.states, props.stateOtherData.LicenseProvince));
		setLicenseType(getNameById(props.listingOptions.brokerLicenseTypeGeneral, props.stateOtherData.LicenseType));
		setSaleType(getNameById(props.listingOptions.brokerQuotaSaleType, props.stateOtherData.SaleType));
		setPleasureboatClass(getNameById(props.listingOptions.brokerPleasureClass, props.stateOtherData.PleasureClass));
		setPleasureboatCategory(getNameById(props.listingOptions.brokerPleasureCategory, props.stateOtherData.PleasureCategory));
		setEquipmentCategory(getNameById(props.listingOptions.brokerEquipmentCategory, props.stateOtherData.EquipmentCategory));
		setEquipmentProductType(getNameById(props.listingOptions.brokerEquipmentProduct, props.stateOtherData.EquipmentProduct));
		setSeafoodSpecies(getNameById(props.listingOptions.brokerGeneralSpecies, props.stateOtherData.SeaFoodSpecies));
		setSeafoodSize(getNameById(props.listingOptions.brokerGeneralSpeciesSize, props.stateOtherData.SeaFoodSize));

		function getNameById(obj, id) {
			if (id > 0) {
				var responseData = Array.from(obj.filter(function (x) {
					return x.id === id
				}));
				return responseData[0].name;
			}
			return 'N/A';
		}
		// eslint-disable-next-line
	}, []);


	return (
		<>
			{(props.stateData.ItemType === 'Boat') ?
				<div>
					<center><img src={Done} className="submitted_icon" alt='Loading...' /></center>
					<p className="your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
					<p className="following_text">Following Are The Details Which You Have Submitted</p>
					<table className="submitted_details">
						<tbody>
							<tr>
								<td className='official_number_text'>Official Number</td>
								<td className='right_text'>{props.stateData.OfficialNumber ? props.stateData.OfficialNumber : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Boat Name</td>
								<td className='right_text'>{props.stateData.VesselName}</td>
							</tr>
							<tr>
								<td className="official_number_text">Dimensions</td>
								<td className='right_text'>Length: {props.stateData.VesselLengthFoot} {props.stateData.VesselLengthUnit === 'MT-CM' ? 'Mt' : 'Ft'} {props.stateData.VesselLengthInch} {props.stateData.VesselLengthUnit === 'MT-CM' ? 'Cm' : 'In'} || Width:
									{props.stateData.VesselWidthFoot} {props.stateData.VesselWidthUnit === 'MT-CM' ? 'Mt' : 'Ft'} {props.stateData.VesselWidthInch} {props.stateData.VesselWidthUnit === 'MT-CM' ? 'Cm' : 'In'}</td>
							</tr>
							<tr>
								<td className="official_number_text">Berth Location</td>
								<td className='right_text'>{berthName}</td>
							</tr>
							<tr>
								<td className="official_number_text">Construction</td>
								<td className='right_text'>{constructionMet}</td>
							</tr>
							<tr>
								<td className="official_number_text">Type Of Engine</td>
								<td className='right_text'>{engineType}</td>
							</tr>
							<tr>
								<td className="official_number_text">Brand Of Engine</td>
								<td className='right_text'>{engineBrand}</td>
							</tr>
							<tr>
								<td className="official_number_text">Type Of Reduction</td>
								<td className='right_text'>{reductionType}</td>
							</tr>
							<tr>
								<td className="official_number_text">Price</td>
								<td className='right_text'>${props.stateData.ItemPrice}</td>
							</tr>
						</tbody>
					</table>
				</div>
				:
				(props.stateData.ItemType === 'License' || props.stateData.ItemType === 'Quota' )
					?
					<div>
						<center><img src={Done} className="submitted_icon" alt='Loading...' /></center>
						<p className="your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
						<p className="following_text">Following Are The Details Which You Have Submitted</p>
						<table className="submitted_details">
							<tbody>
								<tr>
									<td className='official_number_text'>License Number</td>
									<td className='right_text'>{props.stateOtherData.LicenseNumber}</td>
								</tr>
								<tr>
									<td className='official_number_text'>License Species</td>
									<td className='right_text'>{licenseSpecies}</td>
								</tr>
								<tr>
									<td className="official_number_text">License Area</td>
									<td className='right_text'>{licenseArea}</td>
								</tr>
								<tr>
									<td className="official_number_text">Province</td>
									<td className='right_text'>{licenseProvince}</td>
								</tr>
								<tr>
									<td className="official_number_text">License Type</td>
									<td className='right_text'>{licenseType}</td>
								</tr>
								{(props.stateData.ItemType === "Quota") ?
								<tr>
									<td className="official_number_text">Sale Type</td>
									<td className='right_text'>{saleType}</td>
								</tr> : ''}
								<tr>
									<td className="official_number_text"> Price </td>
									<td className='right_text'>${props.stateOtherData.ItemPrice}</td>
								</tr>
							</tbody>
						</table>
					</div>
					:
					(props.stateData.ItemType === 'Pleasure Boat')
						?
						<div>
							<center><img src={Done} className="submitted_icon" alt='Loading...' /></center>
							<p className="your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
							<p className="following_text">Following Are The Details Which You Have Submitted</p>
							<table className="submitted_details">
								<tbody>
									<tr>
										<td className='official_number_text'>Boat Name</td>
										<td className='right_text'>{props.stateOtherData.PleasureBoatName}</td>
									</tr>
									<tr>
										<td className='official_number_text'>Class</td>
										<td className='right_text'>{pleasureboatClass ? pleasureboatClass : 'N/A'}</td>
									</tr>
									<tr>
										<td className="official_number_text">Category</td>
										<td className='right_text'>{pleasureboatCategory ? pleasureboatCategory : 'N/A'}</td>
									</tr>
									<tr>
										<td className="official_number_text">Price</td>
										<td className='right_text'>${props.stateOtherData.ItemPrice}</td>
									</tr>

								</tbody>
							</table>
						</div>
						:
						(props.stateData.ItemType === 'Equipment')
							?
							<div>
								<center><img src={Done} className="submitted_icon" alt='Loading...' /></center>
								<p className="your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
								<p className="following_text">Following Are The Details Which You Have Submitted</p>
								<table className="submitted_details">
									<tbody>
										<tr>
											<td className='official_number_text'>Gear Model</td>
											<td className='right_text'>{props.stateOtherData.EquipmentModel}</td>
										</tr>
										<tr>
											<td className='official_number_text'>Serial Number</td>
											<td className='right_text'>{props.stateOtherData.EquipmentSerialNumber}</td>
										</tr>
										<tr>
											<td className="official_number_text">Manufacturer</td>
											<td className='right_text'>{props.stateOtherData.EquipmentManufacturer}</td>
										</tr>
										<tr>
											<td className="official_number_text">Category</td>
											<td className='right_text'>{equipmentCategory ? equipmentCategory : 'N/A'}</td>
										</tr>
										<tr>
											<td className="official_number_text">Product Type</td>
											<td className='right_text'>{equipmentProductType ? equipmentProductType : 'N/A'}</td>
										</tr>
										<tr>
											<td className="official_number_text"> Year </td>
											<td className='right_text'>${props.stateOtherData.EquipmentYear}</td>
										</tr>
										<tr>
											<td className="official_number_text"> Price </td>
											<td className='right_text'>${props.stateOtherData.ItemPrice}</td>
										</tr>
									</tbody>
								</table>
							</div>
							:
							(props.stateData.ItemType === 'Seafood')
								?
								<div>
									<center><img src={Done} className="submitted_icon" alt='Loading...' /></center>
									<p className="your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
									<p className="following_text">Following Are The Details Which You Have Submitted</p>
									<table className="submitted_details">
										<tbody>
											<tr>
												<td className='official_number_text'>Species </td>
												<td className='right_text'>{seafoodSpecies}</td>
											</tr>
											<tr>
												<td className='official_number_text'>Size</td>
												<td className='right_text'>{seafoodSize}</td>
											</tr>
											<tr>
												<td className="official_number_text">Quantity</td>
												<td className='right_text'>{props.stateOtherData.SeaFoodQuantity}</td>
											</tr>
											<tr>
												<td className="official_number_text">Price</td>
												<td className='right_text'>${props.stateOtherData.ItemPrice}</td>
											</tr>

										</tbody>
									</table>
								</div>
								:
								<div className="text-center">
									<div className="container">
										<div className="row">
											<div className="col-md-12">
												<h2></h2>
											</div>
										</div>
									</div>
								</div>
			}
		</>
	);
}

export default SellerListingCompleted