import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Step11 = ({ values, handleButtonInput, metaData, styles, error }) => {
  return (
    <>
      <p className="good_day_para mt-2">Select The Type Of Service That You Want To Avail</p>
      <div className="button-wrap">
        <input className="hidden radio-label" type="radio" name="AgreementType" id="fsbo-button" checked={values.AgreementType === 'FSBO' ? 'checked' : ''} value="FSBO" onClick={handleButtonInput} />
        <label className="button-label" for="fsbo-button">
          <h1>FSBO</h1>
        </label>
        <input className="hidden radio-label" type="radio" name="AgreementType" id="fsbb-button" checked={values.AgreementType === 'FSBB' ? 'checked' : ''} value="FSBB" onClick={handleButtonInput} />
        <label className="button-label" for="fsbb-button">
          <h1>FSBB</h1>
        </label>
      </div>
      {error && values.AgreementType === '' ? <p className='err-msg mt-2'>Please select one of above</p> : ''}
      {
        (values.AgreementType === 'FSBO') ?
          <a style={{ color: 'black' }} href={metaData[0]} target="_blank" rel="noreferrer">
            <LazyLoadImage src={metaData[2]} alt="Logo" effect="blur" />
            <br />
            Click here to view the Service Agreement
          </a> :
          (values.AgreementType === 'FSBB') ?
            <a style={{ color: 'black' }} href={metaData[1]} target="_blank" rel="noreferrer">
              <LazyLoadImage src={metaData[2]} alt="data" effect="blur" />
              <br />
              Click here to view the Service Agreement
            </a>
            :
            <div></div>
      }
    </>
  );
}

export default Step11;
