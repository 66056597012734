import React from "react";


const Step8 = ({ handleFormData, values, register, error, clearErrors, getValues, trigger }) => {

    // console.log('error step 8==', error)

    return (
        <>
            <p className="good_day_para">How Much Do Willing To Pay</p>
            <span className="popup_input_label">What Is The Minimum Price Willing To Pay:<span className="required-field"></span></span>
            <input className="text-box_2" type="text" placeholder="Ex : $ 1200" name="MinPriceWillingToPay"
                {...register("MinPriceWillingToPay",
                    {
                        required: "This field is required.",
                        valueAsNumber: true,
                        validate: {
                            required: (value) => {
                                if (parseFloat(value) <= 100) {
                                    return "Minimum price should not be less than $100";
                                }
                                else if (value >= getValues('MaxPriceWillingToPay')) {
                                    return 'Minimum price should not be greater or equal to maximum price'
                                }
                            },
                        },
                        pattern: { value: /^([1-9][0-9]*)$/, message: "Minimum Price should be numeric and greater than zero." }
                    }
                )}
                onChange={(e) => {
                    clearErrors("MinPriceWillingToPay");
                    handleFormData(e);
                }} />
            {error.MinPriceWillingToPay && <span className="err-msg position-relative" style={{ top: "-10px" }}>{error?.MinPriceWillingToPay?.message}</span>}
            <span className="popup_input_label">What Is The Maximum Price Willing To Pay:<span className="required-field"></span></span>
            <input className="text-box_2" type="text" placeholder="Ex : $ 3000" name="MaxPriceWillingToPay"
                {...register("MaxPriceWillingToPay",
                    {
                        required: "This field is required.",
                        validate: {
                            maxNumber: (value) => {
                                if (value < 100) {
                                    return "Maximum price should not be less than or equal to $100";
                                }
                                trigger('MinPriceWillingToPay')
                            }
                        },
                        pattern: { value: /^([1-9][0-9]*)$/, message: "Maximum Price should be numeric and greater than zero." }
                    }
                )}
                onChange={(e) => {
                    clearErrors("MaxPriceWillingToPay");
                    handleFormData(e);
                }} />
            {error.MaxPriceWillingToPay && <span className="err-msg position-relative" style={{ top: "-10px" }}>{error.MaxPriceWillingToPay.message}</span>}

        </>
    );
}

export default Step8;
