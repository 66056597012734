import React from "react";
import { Controller } from "react-hook-form";
// import SearchSelect from "../../selectSearch/SearchSelect";
import SearchSelectCreate from "../../selectSearch/SearchSelectCreate";
const Step7 = ({
  handleFormData,
  handleChangeNormal,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  control,
  setValue,
  getValues,

}) => {
  return (
    <>
      <p className="good_day_para">
        Please Provide The Basic Mechanical Information Step 1/2:
      </p>
      <span className="popup_input_label">
        Type Of Engine:<span className="required-field"></span>
      </span>
      <Controller
        name="TypeOfEngine"
        control={control}
        defaultValue=""
        rules={{ required: "This field is required" }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <SearchSelectCreate
            defaultValue={
              selectValues.enginetypes.find(
                (e) => e.id === getValues("TypeOfEngine")
              )
                ? {
                  value: selectValues.enginetypes.find(
                    (e) => e.id === getValues("TypeOfEngine")
                  ).id,
                  label: selectValues.enginetypes.find(
                    (e) => e.id === getValues("TypeOfEngine")
                  )?.name,
                }
                : {
                  value: getValues("TypeOfEngine"),
                  label: getValues("TypeOfEngine"),
                }
            }
            onChange={(data, action) => {
              setValue("TypeOfEngine", data.value);
              handleFormData({
                target: {
                  value: data.value,
                  name: "TypeOfEngine",
                },
              });

              clearErrors("TypeOfEngine");
            }}
            options={selectValues?.enginetypes?.map((enginetype, index) => {
              return { value: enginetype.id, label: enginetype.name };
            })}
          />
        )}
      />
      {error.TypeOfEngine && (
        <span className="err-msg">{error.TypeOfEngine.message}</span>
      )}

      <span className="popup_input_label">
        Brand Of Engine:<span className="required-field"></span>
      </span>
      <Controller
        name="EngineBrandName"
        control={control}
        defaultValue=""
        rules={{ required: "This field is required" }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <SearchSelectCreate
            defaultValue={
              selectValues.enginemanufacturers.find(
                (e) => e.id === getValues("EngineBrandName")
              )
                ? {
                  value: selectValues.enginemanufacturers.find(
                    (e) => e.id === getValues("EngineBrandName")
                  ).id,
                  label: selectValues.enginemanufacturers.find(
                    (e) => e.id === getValues("EngineBrandName")
                  )?.name,
                }
                : {
                  value: getValues("EngineBrandName"),
                  label: getValues("EngineBrandName"),
                }
            }
            onChange={(data, action) => {
              setValue("EngineBrandName", data.value);
              handleFormData({
                target: {
                  value: data.value,
                  name: "EngineBrandName",
                },
              });

              clearErrors("EngineBrandName");
            }}
            options={selectValues?.enginemanufacturers?.map(
              (engineman, index) => {
                return { value: engineman.id, label: engineman.name };
              }
            )}
          />
        )}
      />
      {error.EngineBrandName && (
        <span className="err-msg">{error.EngineBrandName.message}</span>
      )}
      <span className="popup_input_label">Model Number Of Engine:</span>
      <input
        className="text-box_2"
        type="text"
        name="EngineModelNumber"
        placeholder="Ex: E50dplaaa"
        onChange={handleChangeNormal}
        defaultValue={values.EngineModelNumber}
      />
      <span className="popup_input_label">
        Engine Horsepower:<span className="required-field"></span>
      </span>
      <input
        className="text-box_2"
        type="text"
        defaultValue={values.EngineHorsePower}
        name="EngineHorsePower"
        placeholder="Ex: 360-720 HP"
        {...register("EngineHorsePower", {
          required: "This field is required.",
          validate: {
            required: (value) => {
              if (parseFloat(value) <= 0)
                return "Engine horse power should be greater than zero.";
            },
          },
        })}
        onChange={(e) => {
          clearErrors("EngineHorsePower");
          handleFormData(e);
        }}
      />
      {error.EngineHorsePower && (
        <span className="err-msg">{error.EngineHorsePower.message}</span>
      )}
      <span className="popup_input_label">
        How Many Hours On Engine:<span className="required-field"></span>
      </span>
      <input
        className="text-box_2"
        type="text"
        placeholder="Ex: 5412"
        onKeyPress={(event) => {
          if (event.charCode < 48) {
            event.preventDefault();
          }
        }}
        min="0"
        defaultValue={values.EngineHours}
        name="EngineHours"
        {...register("EngineHours", { required: "This field is required" })}
        onChange={(e) => {
          clearErrors("EngineHours");
          handleFormData(e);
        }}
      />
      {error.EngineHours && (
        <span className="err-msg">{error.EngineHours.message}</span>
      )}
    </>
  );
};

export default Step7;
