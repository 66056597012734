import { Url } from "../api_settings/api_constants";
import { wrappedFetch, wrappedGet } from "../api_settings/urlFunctions";





//Get user package api
export const getUserPackage = () => {
    return wrappedGet(Url.GET_USER_PACKAGE, "GET");
};

//Get buyer package api
export const getBuyerPackage = () => {
    return wrappedGet(Url.GET_BUYER_PACKAGE, "GET");
}

//membership unsubscribe
export const unSubscribeUserPlan = (data) => {
    return wrappedFetch(Url.UNSUBSCRIBE_USER_PLAN, "POST", data);
};


