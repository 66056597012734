import { Url } from "../api_settings/api_constants";
import { wrappedFetch, wrappedGet } from "../api_settings/urlFunctions";





//update license listing 
export const updateListing = (data, id) => {
    return wrappedFetch(Url.UPDATE_LISTING_OBJECT + id, "POST", data);
};


// get license object data
export const getListingObjectData = (id) => {
    return wrappedGet(Url.GET_LISTING_OBJECT + id, "GET");
};

// object filter new api
export const objectFilterNew = () => {
    return wrappedGet(Url.OBJECT_LISTING_NEW, "GET");
};
// get license option api
export const getLicenseOptions = () => {
    return wrappedGet(Url.GET_LICENSE_OPTION, "GET");
};

// get equipment options api
export const getEquipmentOptions = () => {
    return wrappedGet(Url.GET_EQUIPMENT_OPTION, "GET");
};

// get pleasureboat options api
export const getPleasureBoatOptions = () => {
    return wrappedGet(Url.GET_PLEASURE_BOAT_OPTION, "GET");
};

// get Sea food options api
export const getSeaFoodOptions = () => {
    return wrappedGet(Url.GET_SEAFOOD_OPTION, "GET");
};

// get Sea food options api
// export const deleteListing = (id) => {
//     return wrappedGet(Url.DELETE_LISTING_SELLER + id, "GET");
// };
export const deleteListing = (data, id) => {
    return wrappedFetch(Url.DELETE_LISTING_SELLER + id, "POST", data);
};


//edit seller listing
export const editSellerListing = (id) => {
    return wrappedGet(Url.EDIT_SELLER_LISTING + id, "GET");
};


//delete seller request
export const deleteSellerRequest = (id) => {
    return wrappedGet(Url.DELETE_SELLER_LISTING + id, "GET");
};


//edit seller request
export const editListingUpdate = (data, id) => {
    return wrappedFetch(Url.EDIT_LISTING_UPDATE + id, "POST", data);
};


export const getListingType = () => {
    return wrappedGet(Url.GET_LISTING_TYPE, "GET");
};



export const getListingTypeFilter = (data) => {
    return wrappedGet(Url.GET_LISTING_TYPE_FILTER, "GET", data);
};

// get sub speciess
export const getSubSpeciesById = (id) => {
    return wrappedGet(Url.GET_SUB_SPECIES + id, "GET");
};



// listing view object
export const listingViewObject = (id) => {
    return wrappedGet(Url.LISTING_VIEW_OBJECT + id, "GET");
};


//listing add photo
export const listingAddPhoto = (data, id) => {
    return wrappedFetch(Url.LISTING_ADD_PHOTO + id, "POST", data);
};


// listing object delete
export const listingDeleteObjectPic = (id, data) => {
    return wrappedFetch(Url.LISTING_DELETE_OBJECT_PHOTO + id, "POST", data);
};


// listing get object
export const listingGetObject = (id) => {
    return wrappedGet(Url.LISTING_GET_OBJECT_PHOTO + id, "GET");
};

//get listing by id
export const getlistingById = (id) => {
    return wrappedGet(Url.GET_LISTNG_BY_ID + id, "GET");
};



export const getFavoritesListing = () => {
    return wrappedGet(Url.GET_FAVORITES_LISTING, "GET");
};

export const deleteFavoriteListing = (id) => {
    return wrappedGet(Url.DELETE_FAVORITE + id, "GET");
};
