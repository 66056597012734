
import React from "react";

const Step1 = ({ userName, handleFormData, values, error }) => {

    return (
        <>
            <p className="good_day_para">Good Day <strong>{userName}</strong>, Please Advise Us,Are You Looking To Sell An Item Today Or Are You Looking To Lease Item Out?</p>
            <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="SellType" value="Sell" id="yes-button" checked={values?.SellType === 'Sell' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="no-button">
                    <h1>Sell</h1>
                </label>
                <input className="hidden radio-label" type="radio" name="SellType" value="Lease" id="no-button" checked={values?.SellType === 'Lease' ? 'checked' : ''} onClick={handleFormData} />
                <label className="button-label" for="yes-button">
                    <h1>Lease</h1>
                </label>
            </div>
            {error && <p className='err-msg'>Please select one of above</p>}
        </>
    );
}

export default Step1;

